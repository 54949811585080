import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Offcanvas } from 'react-bootstrap';
import CalculateCargo from '../../Components/Calculate/CalculateCargo';

const Cargos = () => {
  const [cargoCompany, setCargoCompany] = useState(''); // Seçilen kargo firması state
  const [shipmentKind, setShipmentKind] = useState(''); // Gönderim türü state
  const [payAtDoorPrice, setPayAtDoorPrice] = useState(''); // Kapıda ödeme fiyatı state
  const [showCalculate, setShowCalculate] = useState(false); // Offcanvas durumu
  const [desiPrice, setDesiPrice] = useState(0); // Desi fiyatı
  const [totalDesi, setTotalDesi] = useState(0); // Toplam desi

  // Kargo firmaları listesi (MNG kaldırıldı)
  const cargoCompanies = [
    { name: 'PTT', price: '75,00₺' },
    { name: 'UPS', price: '120,00₺' },
    { name: 'YURTİÇİ', price: '124,00₺' }
  ];

  // Dinamik ödeme seçenekleri için kargo firmasına göre farklılaşan fonksiyon
  const renderPaymentOptions = () => {
    if (cargoCompany === 'PTT' || cargoCompany === 'UPS') {
      return (
        <>
          <Form.Group controlId="shipmentKind">
            <Form.Label>Gönderim Türünü Seçin</Form.Label>
            <Form.Select value={shipmentKind} onChange={(e) => setShipmentKind(e.target.value)}>
              <option value="">Gönderim Türü Seçin</option>
              <option value="kapida">Kapıda Ödeme</option>
              <option value="buyerPaid">Alıcı Ödemeli</option>
              <option value="sellerPaid">Satıcı Ödemeli</option>
              <option value="customerConfirmed">Müşteri Doğrulamalı Kapıda Ödeme</option>
              <option value="online">Online Ödeme Al</option>
            </Form.Select>
          </Form.Group>

          {shipmentKind === 'kapida' && (
            <Form.Group controlId="payAtDoorPrice">
              <Form.Label>Kapıda Ödeme Tutarı</Form.Label>
              <Form.Control
                type="number"
                placeholder="Kapıda Ödeme Tutarı Girin"
                value={payAtDoorPrice}
                onChange={(e) => setPayAtDoorPrice(e.target.value)}
              />
            </Form.Group>
          )}
        </>
      );
    } else if (cargoCompany === 'YURTİÇİ') {
      return (
        <>
          <Form.Group controlId="shipmentKind">
            <Form.Label>Gönderim Türünü Seçin</Form.Label>
            <Form.Select value={shipmentKind} onChange={(e) => setShipmentKind(e.target.value)}>
              <option value="">Gönderim Türü Seçin</option>
              <option value="sellerPaid">Satıcı Ödemeli</option>
              <option value="online">Online Ödeme Al</option>
            </Form.Select>
          </Form.Group>
        </>
      );
    }

    return null;
  };

  // Kargo firması seçimi
  const handleCargoChange = (e) => {
    setCargoCompany(e.target.value); // Seçilen firmayı state'e kaydet
  };

  // Calculate button tıklanınca Offcanvas açma fonksiyonu
  const handleCalculateShow = () => {
    if (cargoCompany) {
      setShowCalculate(true); // Eğer kargo firması seçildiyse aç
    } else {
      alert('Lütfen bir kargo firması seçin.'); // Kargo firması seçilmediyse uyarı ver
    }
  };

  // Calculate Offcanvas'ı kapatma fonksiyonu
  const handleCalculateClose = () => {
    setShowCalculate(false); // Offcanvas'ı kapat
  };

  // Desi ve fiyat hesaplama işlemi
  const handleCalculateCargo = (calculatedDesi, calculatedPrice) => {
    setTotalDesi(calculatedDesi);
    setDesiPrice(calculatedPrice);
  };

  return (
    <Container fluid>
      <Form>
        <Row className="mt-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Kargo Firması</Form.Label>
              <Form.Select onChange={handleCargoChange} value={cargoCompany} required>
                <option value="">Kargo Firması Seçin</option>
                {cargoCompanies.map((company, index) => (
                  <option key={index} value={company.name}>
                    {company.name} - {company.price}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col md={6}>
            {/* Gönderim Türü ve Ödeme Seçenekleri */}
            {renderPaymentOptions()}
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className="text-center">
            <Button className="btn btn-warning w-50" onClick={handleCalculateShow}>
              Kargo Desi / Fiyat Hesapla
            </Button>
          </Col>
        </Row>

        {/* Desi ve Fiyat Bilgilendirme */}
        {totalDesi > 0 && desiPrice > 0 && (
          <Row className="mt-3">
            <Col className="text-center">
              <p>Toplam Desi: {totalDesi} desi</p>
              <p>Kargo Fiyatı: {desiPrice} ₺</p>
            </Col>
          </Row>
        )}
      </Form>

      {/* Offcanvas - CalculateCargo */}
      <Offcanvas show={showCalculate} onHide={handleCalculateClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Kargo Desi/Fiyat Hesapla</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CalculateCargo cargoCompany={cargoCompany} onCalculate={handleCalculateCargo} /> {/* Dinamik kargo firması prop'u */}
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default Cargos;
