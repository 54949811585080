import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Popover, OverlayTrigger, Accordion, Table, Offcanvas, Form } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { BsWallet, BsInfoCircle, BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
import BalanceLoad from '../Panel/Wallet/BalanceLoad';
import axios from 'axios';
import { useSelector } from 'react-redux';
import '../assets/css/wallet.css';

const Wallet = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [balance, setBalance] = useState(0);
  const [blockedBalance, setBlockedBalance] = useState(0);
  const [usableBalance, setUsableBalance] = useState(0);
  const [averageShippingCost, setAverageShippingCost] = useState(0);
  const [transactions, setTransactions] = useState([]);

  // Redux'tan ownerId'yi alıyoruz
const ownerId = useSelector((state) => state.user.userId);

console.log("Redux'tan alınan ownerId:", ownerId);  // ownerId logu
 const apiUrl = process.env.REACT_APP_API_URL; 
  useEffect(() => {
    console.log("useEffect triggered with ownerId:", ownerId);

    if (!ownerId) {
      console.warn('Owner ID is undefined or null. Skipping data fetch.');
      return; // Eğer ownerId null ise, API çağrısını başlatmıyoruz
    }

    // API çağrısını başlatan fonksiyon
    const fetchWalletData = async () => {
      try {
        console.log(`Starting to fetch wallet data for owner ID: ${ownerId}`);

        // Balance API çağrısı
        const balanceResponse = await axios.get(`${apiUrl}/wallet/${ownerId}/balance`);
        console.log('API call successful. Full response:', balanceResponse);

        if (balanceResponse && balanceResponse.data) {
          console.log('Balance Response Data:', balanceResponse.data);
          setBalance(balanceResponse.data.balance || 0);
          setBlockedBalance(balanceResponse.data.blockedBalance || 0);
          setUsableBalance(balanceResponse.data.usableBalance || 0);
          setAverageShippingCost(balanceResponse.data.averageShippingCost || 0);
        } else {
          console.warn('No data received from API.');
        }

        // Transactions API çağrısı
       const transactionsResponse = await axios.get(`${apiUrl}/wallet/${ownerId}/transactions`);
        console.log('Transactions Response Data:', transactionsResponse.data);
        setTransactions(transactionsResponse.data || []);

      } catch (error) {
        console.error('Error occurred while fetching wallet data:', error);
        if (error.response) {
          console.error('Error response data:', error.response.data);
          console.error('Error response status:', error.response.status);
          console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
      }
    };

    // API çağrısını başlatıyoruz
    fetchWalletData();

}, [ownerId]); // ownerId değiştiğinde useEffect tekrar çalışır

  const loadMoreTransactions = async (page) => {
    if (!ownerId) {
      console.warn('Owner ID is undefined or null. Cannot load more transactions.');
      return;
    }

    try {
      console.log('Loading more transactions, page:', page);
      const transactionsResponse = await axios.get(`/wallet/${ownerId}/transactions?page=${page}`);
      console.log('More Transactions Response:', transactionsResponse.data);

      setTransactions((prevTransactions) => [...prevTransactions, ...transactionsResponse.data]);
    } catch (error) {
      console.error('Error loading more transactions:', error);
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p><strong>GÖNDERİ ORTALAMASI</strong></p>
        <p>Son gönderdiğiniz 10 adet kargo taşıma bedelinin ortalaması</p>
        <p><strong>BLOKLANAN BAKİYE</strong></p>
        <p>Oluşturup kargo firmasına henüz teslim etmediğiniz kargoların, gönderi ortalamanız ile çarpımı</p>
        <p><strong>KULLANILABİLİR BAKİYE</strong></p>
        <p>Toplam bakiyenizden, bloklanan bakiye tutarının çıkarılması</p>
        <p><strong>BAKİYE</strong></p>
        <p>Kullanılabilir ve bloklanan bakiyenizin toplamı</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container fluid className="py-4">
      {/* Üst Kısım */}
      <Row className="align-items-center mb-3">
        <Col xs={12} md={6}>
          <h4 className="mb-0">CÜZDAN</h4>
        </Col>
        <Col xs={12} md={6} className="text-end"></Col>
      </Row>

      {/* Bakiye Bilgileri */}
      <Row className="align-items-stretch mb-4">
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill p-3">
            <Row>
              <Col xs={3} className="d-flex align-items-center justify-content-center">
                <BsWallet size={50} />
              </Col>
              <Col xs={9}>
                <h3>{usableBalance.toFixed(2)}₺</h3>
                <Button variant="link" className="p-0" style={{ textDecoration: 'none' }} onClick={() => setShowOffcanvas(true)}>BAKİYE YÜKLE / ÇEK</Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="d-flex">
          <Card className="flex-fill p-3">
            <p className="mb-1">
              Gönderi ortalaması: <strong>{averageShippingCost.toFixed(2)}₺</strong> Bakiye: <strong>{balance.toFixed(2)}₺</strong>
            </p>
            <p className="mb-1">
              Bloklanan Bakiye: <strong>{blockedBalance.toFixed(2)}₺</strong> Kullanılabilir Bakiye: <strong>{usableBalance.toFixed(2)}₺</strong>
            </p>
            <Button variant="link" className="p-0" style={{ textDecoration: 'none' }} onClick={() => loadMoreTransactions(1)}>TÜM HAREKETLER</Button>
            <OverlayTrigger trigger="click" placement="left" overlay={popover}>
              <Button variant="link" className="p-0" style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <BsInfoCircle size={20} />
              </Button>
            </OverlayTrigger>
          </Card>
        </Col>
      </Row>

      {/* Arama ve Filtreleme Bölümü */}
      <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Arama, Filtreleme</Accordion.Header>
          <Accordion.Body>
            <Row className="justify-content-center">
              <Col md={3} className="d-flex justify-content-center">
                <Card className="flex-fill mb-3 text-center">
                  <Card.Header>İşlem Türü</Card.Header>
                  <Card.Body>
                    <div className="d-flex flex-column align-items-center">
                      <Form.Check type="radio" id="kargo" label="Kargo" name="harcamaTuru" />
                      <Form.Check type="radio" id="fatura" label="Fatura" name="harcamaTuru" />
                      <Form.Check type="radio" id="hizmet" label="Hizmet" name="harcamaTuru" />
                    </div>
                    <Button variant="warning" className="w-100 mt-3">Filtrele</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="d-flex justify-content-center">
                <Card className="flex-fill mb-3 text-center">
                  <Card.Header>Harcama Türü</Card.Header>
                  <Card.Body>
                    <div className="d-flex flex-column align-items-center">
                      <Form.Check type="radio" id="para-girisi" label="Para Girişi" name="islemTuru" />
                      <Form.Check type="radio" id="para-cikisi" label="Para Çıkışı" name="islemTuru" />
                      <Form.Check type="radio" id="bloke" label="Bloke" name="islemTuru" />
                    </div>
                    <Button variant="warning" className="w-100 mt-3">Filtrele</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="d-flex justify-content-center">
                <Card className="flex-fill mb-3 text-center">
                  <Card.Header>Tarih Aralığı ile Filtrele</Card.Header>
                  <Card.Body>
                    <Form.Group className="d-flex justify-content-center">
                      <Form.Control type="date" placeholder="Başlangıç Tarihi" className="text-center" style={{ maxWidth: '200px' }} />
                    </Form.Group>
                    <Form.Group className="mt-3 d-flex justify-content-center">
                      <Form.Control type="date" placeholder="Bitiş Tarihi" className="text-center" style={{ maxWidth: '200px' }} />
                    </Form.Group>
                    <Button variant="warning" className="w-100 mt-3">
                      <FaSearch className="me-2" /> Ara
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3} className="d-flex justify-content-center">
                <Card className="flex-fill mb-3 text-center">
                  <Card.Header>Müşteri Ara</Card.Header>
                  <Card.Body>
                    <Form.Group className="d-flex justify-content-center">
                      <Form.Control type="text" placeholder="Müşteri İsim / Soy İsim veya Telefon No ile Arayın" className="text-center" style={{ maxWidth: '200px' }} />
                    </Form.Group>
                    <Button variant="warning" className="w-100 mt-3">
                      <FaSearch className="me-2" /> Ara
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Cüzdan Hareketleri Tablosu */}
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">CÜZDAN HAREKETLERİ</h5>
              <Button variant="outline-warning" onClick={() => loadMoreTransactions(1)}>
                Çıktı Al
              </Button>
            </Card.Header>
            <Card.Body>
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th><BsArrowUpCircle size={20} /></th>
                    <th>İşlem</th>
                    <th>Tutar</th>
                    <th>Harcama Türü</th>
                    <th>Tarih</th>
                    <th>Harcama Detayı</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.type === 'GELİR' ? <BsArrowUpCircle size={20} style={{ color: 'green' }} /> : <BsArrowDownCircle size={20} style={{ color: 'red' }} />}</td>
                      <td style={{ color: transaction.type === 'GELİR' ? 'green' : 'red' }}>{transaction.type}</td>
                      <td style={{ color: transaction.type === 'GELİR' ? 'green' : 'red' }}>{transaction.amount}₺</td>
                      <td>{transaction.category}</td>
                      <td>{new Date(transaction.date).toLocaleDateString()}</td>
                      <td>{transaction.details}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Offcanvas for Bakiye Yükle / Çek */}
      <Offcanvas 
        show={showOffcanvas} 
        onHide={() => setShowOffcanvas(false)} 
        placement="end"
        backdrop={true}
        scroll={true}
      >
        <Offcanvas.Header closeButton className='text-warning'>
          <Offcanvas.Title className="w-100 text-center">BAKİYE YÜKLE / ÇEK</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <BalanceLoad />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default Wallet;
