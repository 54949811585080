import React, { useState } from 'react';
import { Offcanvas, Card, Form, Button, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import logo from '../../assets/images/Kargo/indirimli.png';
import OnlinePaymentConfirmation from './Online';
import CreatePage from './Create'; // Import the CreatePage component

const ExportForm = ({ show, onHide }) => {
  const [showOnlineConfirmation, setShowOnlineConfirmation] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showCreatePage, setShowCreatePage] = useState(false); // State for showing the CreatePage

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    setShowOnlineConfirmation(e.target.checked);
  };

  const handleCreateClick = () => {
    setShowCreatePage(true);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <Card className="p-3">
          <h6>İhracat Artık Gözünü Korkutmasın</h6>
          <p>Bizimle yurtdışı gönderi yapmak hem ekonomik hem kolay!</p>
          <ul className="pl-3">
            <li>Kolayca Fiyat Hesapla</li>
            <li>Gönderi Bilgilerini Gir</li>
            <li>Dilersen Yurtdışı Ödeme Al</li>
            <li>Ürünlerini Bize Gönder</li>
            <li>Gönderini Hızlı Şekilde Alıcıda</li>
          </ul>
        </Card>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Offcanvas show={show} onHide={onHide} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <span className="d-flex align-items-center">
              <img
                src={logo}
                alt="İndirimli Kargo"
                style={{ width: '24px', marginRight: '8px' }}
              />
              E-İHRACAT (YURTDIŞI GÖNDERİ)
            </span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="mb-3">
            <Card.Body>
              <Form>
                {/* Package Info Section */}
                <div className="mb-3">
                  <h6 className="mb-2">
                    Öncelikle Gönderim yapacağınız ülke ve paket ölçülerini girerek fiyat tekliflerini
                    görüntüleyebilirsiniz. Dilerseniz sonrasında alıcı bilgilerini girerek, kolayca
                    yurtdışı gönderi yapabilirsiniz.
                  </h6>
                </div>

                <Form.Group as={Row} className="mb-3">
                  <Col xs={6}>
                    <Form.Label>Ülke</Form.Label>
                    <Form.Select>
                      <option>Ülke seçin</option>
                      {/* Add options dynamically */}
                    </Form.Select>
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Şehir</Form.Label>
                    <Form.Select>
                      <option>Şehir seçin</option>
                      {/* Add options dynamically */}
                    </Form.Select>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Col xs={6}>
                    <Form.Label>En</Form.Label>
                    <Form.Control type="text" placeholder="Cm" />
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Boy</Form.Label>
                    <Form.Control type="text" placeholder="Cm" />
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Yükseklik</Form.Label>
                    <Form.Control type="text" placeholder="Cm" />
                  </Col>
                  <Col xs={6}>
                    <Form.Label>Ağırlık</Form.Label>
                    <Form.Control type="text" placeholder="Kg" />
                  </Col>
                </Form.Group>

                {/* Prices Section */}
                <Form.Group className="mb-3">
                  <Form.Label>Fiyatlar</Form.Label>
                  <div className="d-flex justify-content-between flex-wrap">
                    <Form.Check
                      type="radio"
                      label="UPS 15,00₺"
                      name="fiyat"
                      id="ups"
                    />
                    <Form.Check
                      type="radio"
                      label="TNT 19,00₺"
                      name="fiyat"
                      id="tnt"
                    />
                    <Form.Check
                      type="radio"
                      label="DHL 16,50₺"
                      name="fiyat"
                      id="dhl"
                    />
                    <Form.Check
                      type="radio"
                      label="FEDEX 14,50₺"
                      name="fiyat"
                      id="fedex"
                    />
                    <Form.Check
                      type="radio"
                      label="TURPEX 17,25₺"
                      name="fiyat"
                      id="turpex"
                    />
                  </div>
                </Form.Group>

                {/* Delivery Details Section */}
                <Form.Group className="mb-3">
                  <Form.Label>Gönderici Adresi Seç, Ekle</Form.Label>
                  <Form.Select>
                    <option>Gönderici Adresini Seçin</option>
                    {/* Add options dynamically */}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Alıcı Adresi Seç, Ekle</Form.Label>
                  <Form.Select>
                    <option>Alıcı Adresini Seçin</option>
                    {/* Add options dynamically */}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Ürün Seç, Ekle</Form.Label>
                  <Form.Select>
                    <option>Ürünü Seçin</option>
                    {/* Add options dynamically */}
                  </Form.Select>
                </Form.Group>

                {/* Payment Option */}
                <Form.Group className="mb-3 d-flex align-items-start">
                  <Form.Check
                    type="checkbox"
                    id="onlinePayment"
                    checked={isCheckboxChecked}
                    onChange={handleCheckboxChange}
                    style={{ marginTop: '0.3rem' }}
                  />
                  <div style={{ marginLeft: '0.5rem' }}>
                    <Form.Label
                      htmlFor="onlinePayment"
                      style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    >
                      Sipariş Bedelini Link ile Online Tahsil Et (Ödeme Al)
                    </Form.Label>
                    <Form.Text className="text-muted">
                      Dilersen %5 Banka komisyonu ile EURO, DOLAR veya TÜRK LİRASI para birimleri ile
                      sipariş bedelini müşterinden Online Tahsil edebilirsin.
                    </Form.Text>
                  </div>
                </Form.Group>

                {/* Information Section */}
                <div className="mb-3">
                  <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                    <span style={{ color: '#0d6efd', cursor: 'pointer' }}>Nasıl Çalışır?</span>
                  </OverlayTrigger>
                </div>

                <Button variant="warning" className="w-100 mt-3" onClick={handleCreateClick}>
                  Oluştur
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Online Payment Confirmation Offcanvas */}
      <OnlinePaymentConfirmation
        show={showOnlineConfirmation}
        onHide={() => setShowOnlineConfirmation(false)}
      />

      {/* Create Page Offcanvas */}
      <Offcanvas show={showCreatePage} onHide={() => setShowCreatePage(false)} placement="end">
        <CreatePage />
      </Offcanvas>
    </>
  );
};

export default ExportForm;
