import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const Profile = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    tcNo: '',
    birthDate: '',
    email: '',
    phone: '',
    profileImage: '',
    senderName: '',
    companyName: '',
    taxNumber: '',
    taxOffice: '',
    address: '', // Adres bilgisi buraya gelecek
    city: '',  // Şehir bilgisi
    district: '' // İlçe bilgisi
  });

  const [logoPreview, setLogoPreview] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(''); // Güncelleme durumu için state

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
        headers: { 'x-auth-token': token }
      });
      const userData = response.data;

      setUser({
        ...user,
        firstName: userData.firstName || '',
        lastName: userData.lastName || '',
        tcNo: userData.tcNo || '',
        birthDate: userData.birthDate || '',
        email: userData.email || '',
        phone: userData.phone || '',
        profileImage: userData.profileImage || '',
        senderName: userData.senderName || '',
        companyName: userData.companyName || '',
        taxNumber: userData.taxNumber || '',
        taxOffice: userData.taxOffice || '',
        address: userData.address || '',
        city: userData.city || '',
        district: userData.district || ''
      });

      if (userData.profileImage) {
        setLogoPreview(userData.profileImage);
      }
    } catch (error) {
      console.error('Kullanıcı verileri alınırken bir hata oluştu:', error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh', border: '1px solid black' }}>
      <Row className="w-100 justify-content-center">
        <Col md={8} className="text-center">
          <div className="p-4" style={{ backgroundColor: 'white', borderRadius: '30px', color: '#333' }}>
            <div className="profile-avatar mb-4" style={{ textAlign: 'center' }}>
              {user.profileImage ? (
                <img 
                  src={user.profileImage} 
                  alt="Profile" 
                  className="rounded-circle mx-auto" 
                  style={{ width: '120px', height: '120px', objectFit: 'cover' }}
                />
              ) : (
                <div 
                  className="d-flex align-items-center justify-content-center mx-auto"
                  style={{ 
                    width: '120px', 
                    height: '120px', 
                    fontSize: '36px',
                    fontWeight: 'bold',
                    color: 'black',
                    backgroundColor: '#FFD700',
                    borderRadius: '50%',
                  }}>
                  {user.firstName && user.lastName
                    ? `${user.firstName?.[0]}${user.lastName?.[0]}`
                    : 'N/A'}
                </div>
              )}
              <h4 className="mt-3">
                {user.firstName || 'Ad'}
                {' '}
                {user.lastName || 'Soyad'}
              </h4>
              <p>{user.email || 'Email eklenmedi'}</p>
              <p>{user.phone || 'Telefon eklenmedi'}</p>
            </div>

            <h5 className="mt-4">ADRES BİLGİLERİ</h5>
            <hr />

            {user.address ? (
              <div className="mb-4 p-3" style={{ border: '1px solid #FFD700', borderRadius: '10px' }}>
                <p><strong>{user.firstName || 'Ad'} {user.lastName || 'Soyad'}</strong></p>
                <p>{user.address || 'Adres eklenmedi'}</p>
                <p>{user.city || 'Şehir eklenmedi'}, {user.district || 'İlçe eklenmedi'}</p>
                <p>{user.email || 'Email eklenmedi'}</p>
                <p>{user.phone || 'Telefon eklenmedi'}</p>
              </div>
            ) : (
              <p>Henüz bir adres eklemediniz.</p>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
