import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './Layout/Layout';
import Login from './Components/Authentication/Login';
import Register from './Components/Authentication/Register';
import ForgotPassword from './Components/Authentication/ForgotPassword';
import ResetPassword from './Components/Authentication/ResetPassword';
import Welcome from './Components/Authentication/Welcome';

import KargoInfo from './Components/Authentication/kargom/KargoInfo';
import Efatura from './Components/Authentication/kargom/Efatura';
import Api from './Components/Authentication/kargom/Api';

import DashboardRouter from './Panel/DashboardRouter';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />}>
          <Route index element={<Home />} />
          <Route path="/nasil-calisir" element={<Home scrollTarget="nasil-calisir" />} />
          <Route path="/fiyatlar" element={<Home scrollTarget="fiyatlar" />} />
          <Route path="/hizmetlerimiz" element={<Home scrollTarget="hizmetlerimiz" />} />
          <Route path="/iletişim" element={<Home scrollTarget="iletişim" />} />
          <Route path="/giriş-yap" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/üye-ol" element={<Register />} />
          <Route path="/hoşgeldiniz" element={<Welcome />} />
          <Route path="/sifremi-unuttum" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} /> 
          <Route path="/nasıl-kargo-gönderirim" element={<KargoInfo />} /> 
          <Route path="/nasıl-efatura-kullanırım" element={<Efatura />} />  
          <Route path="/nasıl-api-entegrasyonu-yaparım" element={<Api />} /> 
          {/* <Route path="/panelim" element={<DashboardRouter />} />  */}
             <Route path="/panelim/*" element={<DashboardRouter />} />
          
        </Route>
       
      </Routes>
    </Router>
  );
}

export default App;
