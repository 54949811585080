import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ListGroup, DropdownButton, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import { FaHome, FaFileInvoice, FaWhatsapp, FaUsers, FaChartBar, FaCog, FaBoxes, FaUser, FaBell } from 'react-icons/fa'; 
import { MdAccountBalanceWallet } from 'react-icons/md';
import { TbWorldShare } from "react-icons/tb";
import logo from '../assets/images/Kargo/indirimli.png'; // Logo yolu

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [userName, setUserName] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL; // .env dosyasındaki dinamik API URL'sini alın

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const token = localStorage.getItem('token'); // Token'ı local storage'dan alın

        const response = await fetch(`${apiUrl}/user`, { // Dinamik API URL'si
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token // Token'ı header'a ekleyin
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json(); // Yanıtı JSON olarak parse et
        setUserName(`${data.firstName} ${data.lastName}`);
      } catch (error) {
        console.error('Kullanıcı adı alınırken bir hata oluştu: ', error);
      }
    };

    fetchUserName();
  }, [apiUrl]); // API URL'si değiştiğinde yeniden veri çekilmesini sağlar

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const popover = (
    <Popover
      id="popover-help"
      style={{
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: '15px',
        width: '250px',
      }}
    >
      <Popover.Header
        as="h3"
        style={{
          borderBottom: 'none',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        Yardım
      </Popover.Header>
      <Popover.Body>
        <div style={{ textAlign: 'center', fontSize: '14px' }}>
          Bizimle <FaWhatsapp style={{ color: '#25D366' }} /> WhatsApp üzerinden <br />
          <strong style={{ color: 'red' }}>0850 304 0 555</strong> <br />
          numarası ile iletişime geçebilirsiniz.
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container fluid className='bg-light' style={{ paddingTop: '70px' }}>
      <Row>
        {/* Sidebar */}
        <Col lg={isSidebarOpen ? 2 : 1} md={isSidebarOpen ? 3 : 1} className="bg-dark text-white p-3 sidebar" style={{ minHeight: '100vh' }}>
          <Button variant="outline-light" className="mb-3" onClick={toggleSidebar}>
            {isSidebarOpen ? '‹' : '›'}
          </Button>
          <div className="text-center mb-3">
            <img 
              src={logo} 
              alt="İndirimli Kargo Logo" 
              className="img-fluid mb-2" 
              style={{ width: isSidebarOpen ? '100px' : '50px', transition: 'width 0.3s' }} 
            />
            {isSidebarOpen && (
              <>
                <h5>İNDİRİMLİ KARGO</h5>
                <p style={{ backgroundColor: 'yellow', color: 'black', padding: '5px', borderRadius: '5px' }}>
                  {userName}
                </p>
              </>
            )}
          </div>
          <ListGroup variant="flush">
            {/* Ana Sayfa Linki */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim" className="text-white d-flex align-items-center">
                <FaHome className="me-2" /> {isSidebarOpen && 'Ana Sayfa'}
              </Link>
            </ListGroup.Item>
            {/* Hesabım */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/account" className="text-white d-flex align-items-center">
                <FaUser className="me-2" /> {isSidebarOpen && 'Hesabım'}
              </Link>
            </ListGroup.Item>
            {/* Kargolar */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/shipments" className="text-white d-flex align-items-center">
                <FaBoxes className="me-2" /> {isSidebarOpen && 'Kargolar'}
              </Link>
            </ListGroup.Item>
            {/* Faturalarım */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/invoices" className="text-white d-flex align-items-center">
                <FaFileInvoice className="me-2" /> {isSidebarOpen && 'Faturalarım'}
              </Link>
            </ListGroup.Item>
            {/* Cüzdanım */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/wallet" className="text-white d-flex align-items-center">
                <MdAccountBalanceWallet className="me-2" /> {isSidebarOpen && 'Cüzdanım'}
              </Link>
            </ListGroup.Item>
            {/* Müşterilerim */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/customers" className="text-white d-flex align-items-center">
                <FaUsers className="me-2" /> {isSidebarOpen && 'Müşterilerim'}
              </Link>
            </ListGroup.Item>
            {/* Online Ödemeler */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/payments" className="text-white d-flex align-items-center">
                <FaChartBar className="me-2" /> {isSidebarOpen && 'Online Ödemeler'}
              </Link>
            </ListGroup.Item>
            {/* İhracat Menüsü */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/export" className="text-white d-flex align-items-center">
                <TbWorldShare className="me-2" /> {isSidebarOpen && 'İhracat (Yurtdışı Gönderi)'}
              </Link>
            </ListGroup.Item>
            {/* API Entegrasyon */}
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/integration" className="text-white d-flex align-items-center">
                <FaCog className="me-2" /> {isSidebarOpen && 'API Entegrasyon'}
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col lg={isSidebarOpen ? 10 : 11} md={isSidebarOpen ? 9 : 11}>
          {/* Üst Menü */}
          <Row className="align-items-center py-1" style={{ borderBottom: '1px solid #ddd', marginLeft: isSidebarOpen ? '20px' : '10px' }}>
            <Col xs={6} md={6} lg={4} className="text-start">
              <h5 className="mb-0" style={{ paddingLeft: '20px' }}>İNDİRİMLİ KARGO</h5>
            </Col>

            <Col xs={6} md={6} className="d-flex align-items-center justify-content-end">
              <a href="/profile" className="me-2 text-dark">
                <FaUser size={20} />
              </a>
              <a href="/notifications" className="me-2 text-dark">
                <FaBell size={20} />
              </a>
              <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                <span
                  style={{
                    color: '#000',
                    textDecoration: 'none',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '5px 10px',
                    cursor: 'pointer',
                  }}
                  className="me-2"
                >
                  Yardım ?
                </span>
              </OverlayTrigger>
              <DropdownButton id="dropdown-basic-button" title="+ Oluştur" variant="warning">
                <Dropdown.Item href="/kargo-olustur">Kargo Oluştur</Dropdown.Item>
                <Dropdown.Item href="/efatura-olustur">E-Fatura Oluştur</Dropdown.Item>
                <Dropdown.Item href="/yeni-musteri-ekle">Yeni Müşteri Ekle</Dropdown.Item>
                <Dropdown.Item href="/yurtdisi-kargo-olustur">Yurtdışı Kargo Oluştur</Dropdown.Item>
                <Dropdown.Item href="/link-ile-odeme-al">Link ile Ödeme Al</Dropdown.Item>
                <Dropdown.Item href="/kapida-odemeli-sat">Kapıda Ödemeli Sat</Dropdown.Item>
              </DropdownButton>
            </Col>
          </Row>

          {/* İçerik Bölümü */}
          <div className="content p-4" style={{ borderLeft: '1px solid #ddd' }}>
            <Outlet /> {/* Bu, route'lara göre değişen içerik bileşenlerini göstermek için kullanılıyor */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
