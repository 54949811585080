import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPhoneAlt, FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import logo from '../../assets/images/Kargo/indirimli.png';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <Container 
      fluid 
      className="d-flex align-items-center justify-content-center min-vh-100" 
      style={{ padding: '5px', marginTop: '20px' }} // Buraya marginTop ekledik
    >
      <Row className="justify-content-center align-items-center w-100">
        <Col 
          xs={12} sm={10} md={8} lg={6} xl={4} 
          className="p-2" 
          style={{ 
            backgroundColor: '#fff', 
            borderRadius: '10px', 
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', 
            maxWidth: '300px' 
          }}
        >
          <div className="text-center mb-3">
            <Image src={logo} alt="İndirimli Kargo Logo" style={{ width: '35px' }} />
            <h3 className="text-center mt-2" style={{ fontSize: '16px' }}>Aramıza Hoş Geldiniz!</h3>
            <p style={{ fontSize: '12px' }}>Avantajlı Hizmetlerimizi Kullanmaya Hemen Başlayabilirsin!</p>
            <p style={{ fontSize: '12px' }}>Yardım mı ihtiyacın var?</p>
          </div>

          <div className="text-start mb-3">
            <Button 
              variant="outline-dark" 
              className="w-100 mb-2" 
              style={{ 
                border: '1px solid #ccc', 
                padding: '8px', 
                borderRadius: '5px', 
                fontSize: '10px' 
              }}
              onClick={() => navigate('/nasıl-kargo-gönderirim')}
            >
              NASIL KARGO GÖNDEREBİLİRİM?
            </Button>
            <Button 
              variant="outline-dark" 
              className="w-100 mb-2" 
              style={{ 
                border: '1px solid #ccc', 
                padding: '8px', 
                borderRadius: '5px', 
                fontSize: '10px' 
              }}
              onClick={() => navigate('/nasıl-efatura-kullanırım')}
            >
              NASIL E-FATURA KULLANIRIM?
            </Button>
            <Button 
              variant="outline-dark" 
              className="w-100 mb-2" 
              style={{ 
                border: '1px solid #ccc', 
                padding: '8px', 
                borderRadius: '5px', 
                fontSize: '10px' 
              }}
              onClick={() => navigate('/nasıl-api-entegrasyonu-yaparım')}
            >
              NASIL API ENTEGRASYONU YAPARIM?
            </Button>
          </div>

          <div className="text-center mt-3">
            <p style={{ fontSize: '12px' }}>Halen Yardıma İhtiyacın Varsa</p>
            <div className="mb-2" style={{ fontSize: '12px' }}>
              <FaPhoneAlt size={14} className="me-2" />
              <span>Bizi Arayabilir!</span>
              <br />
              <span className="small">0850 304 0555</span>
            </div>
            <div className="mb-2" style={{ fontSize: '12px' }}>
              <FaWhatsapp size={14} className="me-2" style={{ color: '#25D366' }} /> {/* WhatsApp ikonu yeşil */}
              <span>Mesaj Yazabilir!</span>
              <br />
              <span className="small">0850 304 0555</span>
            </div>
            <div className="mb-2" style={{ fontSize: '12px' }}>
              <FaEnvelope size={14} className="me-2" />
              <span>E-Posta Gönderebilirsiniz</span>
              <br />
              <span className="small">destek@indirimlikargo.com</span>
            </div>
          </div>
        </Col>

      </Row>
    
    </Container>
  );
};

export default Welcome;
