import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFileAlt } from 'react-icons/fa'; 
import logo from '../../assets/images/Kargo/indirimli.png';
import '../../assets/css/Register.css';
import Footer from '../Footer';
import axios from 'axios';

const Register = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '', // Şifreyi doğrulama alanı
    phone: '',
    tcNo: '',
    birthDate: '',
    city: '',
    district: '',
    address: '',
    logoupload: '',
    taxType: '', // Vergi Türü
    companyName: '',
    taxNumber: '',
    taxOffice: '',
    companyCity: '',
    companyDistrict: '',
    companyAddress: '',
    marketingConsent: false
  });

  const [passwordError, setPasswordError] = useState('');
  const [logoFile, setLogoFile] = useState(null);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios.get('https://turkiyeapi.dev/api/v1/provinces')
      .then(response => {
        setCities(response.data.data);
      })
      .catch(error => {
        console.error('İl listesi alınamadı:', error);
      });
  }, []);

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setSelectedCity(cityId);
    setFormData({ ...formData, city: cityId });

    axios.get(`https://turkiyeapi.dev/api/v1/provinces/${cityId}`)
      .then(response => {
        setDistricts(response.data.data.districts);
      })
      .catch(error => {
        console.error('İlçe listesi alınamadı:', error);
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoFile(file);
    }
  };

  const handleTaxTypeChange = (e) => {
    setFormData({ ...formData, taxType: e.target.value });
  };

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;

    return hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && isLongEnough;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword(formData.password)) {
      setPasswordError('Şifre en az 8 karakter, bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir.');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Şifreler uyuşmuyor.');
      return;
    }

    setPasswordError('');

    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSubmit.append(key, formData[key]);
    });

    if (logoFile) {
      formDataToSubmit.append('logo', logoFile);
    }

    try {
      const response = await axios.post(`${apiUrl}/auth/register`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Kayıt başarılı:', response.data);
      navigate('/giriş-yap');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.msg || 'Kayıt başarısız.');
      } else {
        setError('Kayıt işlemi sırasında bir hata oluştu.');
      }
    }
  };

  return (
    <>
      <Container fluid className="register-form vh-100 d-flex align-items-center justify-content-center">
        <Row className="w-100">
          <Col md={9} className="text-light p-5">
            <h2 className="mb-4 text-center">KAYIT OL!</h2>
            <p className="text-center">
              Zaten Üyemisin? <Link to="/giriş-yap" style={{ color: '#f0ad4e' }}>Buradan Giriş Yap</Link>
            </p>

            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className="register-individual-form mb-1">
                  <Form.Group controlId="formFirstName">
                    <Form.Label>Ad</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Adınız" 
                      name="firstName" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="ind-form mb-3">
                  <Form.Group controlId="formLastName">
                    <Form.Label>Soyad</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Soyadınız" 
                      name="lastName" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Group controlId="formPhone">
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control 
                      type="tel" 
                      placeholder="Telefon Numaranız" 
                      name="phone" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="register-mail mb-3">
                  <Form.Group controlId="formEmail">
                    <Form.Label>E-posta</Form.Label>
                    <Form.Control 
                      type="email" 
                      placeholder="E-posta Adresiniz" 
                      name="email" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Group controlId="formTCKN">
                    <Form.Label>TC Kimlik No</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="TC Kimlik Numaranız" 
                      name="tcNo" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formBirthDate">
                    <Form.Label>Doğum Tarihi</Form.Label>
                    <Form.Control 
                      type="date" 
                      name="birthDate" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Group controlId="formPassword">
                    <Form.Label>Şifre *</Form.Label>
                    <Form.Control 
                      type="password" 
                      placeholder="Şifrenizi Girin" 
                      name="password" 
                      onChange={handleChange} 
                      autoComplete="new-password"  
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-3">
                  <Form.Group controlId="formConfirmPassword">
                    <Form.Label>Şifreyi Doğrulayın *</Form.Label>
                    <Form.Control 
                      type="password" 
                      placeholder="Şifrenizi Tekrar Girin" 
                      name="confirmPassword" 
                      onChange={handleChange} 
                      autoComplete="new-password"  
                    />
                  </Form.Group>
                </Col>

                {passwordError && (
                  <Col md={12} className="mb-3">
                    <p className="text-danger">{passwordError}</p>
                  </Col>
                )}
              </Row>

              <p className="text-light small mt-3">
                Kimlik Bilgileriniz Ülkemiz Taşımacılık Kanunları Gereği İstenmekte olup, E-Devlet Sistemi üzerinden doğrulanmaktadır. Kişisel verileriniz; K.V.K.K hükümlerine uygun olarak Kişisel Verilerin Korunması Politikamızla korunarak işlenmektedir.
              </p>

              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formCity">
                    <Form.Label>İl</Form.Label>
                    <Form.Control 
                      as="select" 
                      name="city" 
                      onChange={handleCityChange}>
                      <option>İl Seçiniz</option>
                      {cities.map(city => (
                        <option key={city.id} value={city.id}>{city.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formDistrict">
                    <Form.Label>İlçe</Form.Label>
                    <Form.Control 
                      as="select" 
                      name="district" 
                      onChange={handleChange} 
                      disabled={!selectedCity}>
                      <option>İlçe Seçiniz</option>
                      {districts.map(district => (
                        <option key={district.id} value={district.id}>{district.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group controlId="formAddress">
                    <Form.Label>Adres</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Tam Adresinizi Girin" 
                      name="address" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>
              </Row>
              
              <Row>
                <Col md={12} className="mb-3">
                  <h5>Gönderici Adı (Zorunlu Değil)</h5>
                  <p className="text-light" style={{ border: '1px solid #f0ad4e', padding: '10px', borderRadius: '5px' }}>
                    ALICILARIN GÖNDERİCİ OLARAK GÖRECEKLERİ MAĞAZA / MARKA İSMİNİZİ EKLEYEBİLİRSİNİZ. GİRİŞ YAPMAZSANIZ GÖNDERİCİ OLARAK AD SOYAD BİLGİNİZ PAYLAŞILIR.
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-3">
                  <h5>GÖNDERİCİ MAĞAZA LOGO (Zorunlu Değil)</h5>
                  <div className="d-flex align-items-center" style={{ border: '1px solid #f0ad4e', padding: '10px', borderRadius: '5px' }}>
                    <FaFileAlt size={50} className="me-3 text-light" />
                    <div>
                      <p>Dilerseniz buradan mağaza logonuzu sisteme ekleyerek,</p>
                      <p>Kargo takip sayfasına mağaza logonuzu ekleyebilir,</p>
                      <p>Alıcılar (müşteriler) için kurumsal bir görünüm elde edebilirsiniz.</p>
                    </div>
                    <div className="ms-auto">
                      <Form.Control 
                        type="file"
                        id="formLogo" 
                        name='logoupload'
                        onChange={handleFileChange} 
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Form.Group controlId="formTaxType" className='mb-5'>
                <Form.Label>Vergi Türü</Form.Label>
                <Form.Control 
                  as="select" 
                  name="taxType" 
                  onChange={handleTaxTypeChange}
                  value={formData.taxType}
                  required
                >
                  <option value="">Seçiniz</option>
                  <option value="individual">Vergi mükellefi değilim veya vergiden muaf esnafım (Bireysel)</option>
                  <option value="company">Şirket sahibiyim (Adi ortaklık, Limited, A.Ş. Kurumsal)</option>
                </Form.Control>
              </Form.Group>

              <Row>
                <Col md={12} className="mb-3">
                  <h4 className="text-center">KURUMSAL ŞİRKET BİLGİLERİ</h4>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="formCompanyName">
                    <Form.Label>* Şirket Ünvanı</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Şirket Ünvanı" 
                      name="companyName" 
                      className="border-warning" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formTaxNumber">
                    <Form.Label>* Vergi No</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Vergi No" 
                      name="taxNumber" 
                      className="border-warning" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formTaxOffice">
                    <Form.Label>* Vergi Dairesi</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Vergi Dairesi" 
                      name="taxOffice" 
                      className="border-warning" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formCompanyCity">
                    <Form.Label>* İl</Form.Label>
                    <Form.Control 
                      as="select" 
                      name="companyCity" 
                      className="border-warning" 
                      onChange={handleCityChange}>
                      <option>İl Seçiniz</option>
                      {cities.map(city => (
                        <option key={city.id} value={city.id}>{city.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group controlId="formCompanyDistrict">
                    <Form.Label>* İlçe</Form.Label>
                    <Form.Control 
                      as="select" 
                      name="companyDistrict" 
                      className="border-warning" 
                      onChange={handleChange} 
                      disabled={!selectedCity}>
                      <option>İlçe Seçiniz</option>
                      {districts.map(district => (
                        <option key={district.id} value={district.id}>{district.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={12} className="mb-3">
                  <Form.Group controlId="formCompanyAddress">
                    <Form.Label>* Şirket Tam Adres</Form.Label>
                    <Form.Control 
                      type="text" 
                      placeholder="Şirket Tam Adres" 
                      name="companyAddress" 
                      className="border-warning" 
                      onChange={handleChange} 
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={12}>
                  <Form.Group controlId="formMarketing">
                    <Form.Check 
                      type="checkbox" 
                      label="Pazarlama amacıyla tarafımla iletişime geçilmesini kabul ediyorum." 
                      name="marketingConsent" 
                      onChange={(e) => setFormData({ ...formData, marketingConsent: e.target.checked })}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group controlId="formPolicyAgreement">
                    <Form.Check 
                      type="checkbox" 
                      label={
                        <>
                          Kayıt olarak <a href="/aydınlatma-metni">Aydınlatma Metnini</a>, <a href="/gizlilik-politikası">Gizlilik ve Çerez Politikasını</a>, 
                          <a href="/kişisel-veri-politikası"> Kişisel Verileri Koruma ve İşleme Politikasını</a> ve 
                          <a href="/üyelik-sözleşmesi"> Müşteri Üyelik Sözleşmesini</a> okudum, kabul ediyorum.
                        </>
                      } 
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button type="submit" variant="warning" className="w-100  mb-7">Üye Ol</Button>
              {error && <p className="text-danger mt-3">{error}</p>} 
            </Form>
          </Col>

         <Col md={3} className="p-4" style={{ marginTop: '20px', color: 'white', borderLeft: '1px solid white' }}>
  <div className="text-center mb-4 d-flex align-items-center justify-content-center">
    <Image src={logo} alt="İndirimli Kargo Logo" style={{ width: '30px', marginRight: '10px' }} />
    <h4 className="mb-0">İNDİRİMLİ KARGO</h4>
  </div>
  <hr />
  <div className="text-center"> {/* Metin bu div içinde ortalanıyor */}
    <p className="small text-white">
      İndirimli Kargo’nun avantajlarla dolu dünyası bir kaç tık uzağınızda.
    </p>
    <p className="small text-white">
      Desteğe ihtiyacınız olması durumunda; <br />
      <strong>0 850 304 0 555</strong> numaralı çağrı merkezimizi arayabilir <br />
      ya da aynı numara üzerinden Whatsapp Kurumsal hattımıza mesaj atabilir <br />
      veya <strong>destek@indirimlikargo.com</strong> adresine e-posta göndererek bizlere ulaşabilirsiniz.
    </p>
  </div>
</Col>

        </Row>
      </Container >
      <Footer/> 
    </>
  );
};

export default Register;
