import React, { useState } from 'react';
import { Offcanvas, Button, Form, Row, Col } from 'react-bootstrap';

const InvoiceForm = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    city: '',
    district: '',
    fullAddress: '',
    isCorporate: false,
    companyTitle: '',
    taxNumber: '',
    taxOffice: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Form gönderme işlemi burada yapılacak
    console.log(formData);
    handleClose();
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="w-100 text-center">E-Fatura Düzenle</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleSubmit} className="text-center">
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label>Ad</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  className="text-center"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label>Soyad</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  className="text-center"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="phone">
                <Form.Label>Telefon No</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="text-center"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>E-Posta</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="text-center"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="city">
                <Form.Label>İl</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  className="text-center"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="district">
                <Form.Label>İlçe</Form.Label>
                <Form.Control
                  type="text"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  required
                  className="text-center"
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="fullAddress" className="mt-3">
            <Form.Label>Tam Adres</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="fullAddress"
              value={formData.fullAddress}
              onChange={handleChange}
              required
              className="text-center"
            />
          </Form.Group>

          <Form.Check
            type="checkbox"
            label="Kurumsal Şirket bilgilerinizi eklemeye ihtiyacınız varsa işaretleyiniz."
            name="isCorporate"
            checked={formData.isCorporate}
            onChange={handleChange}
            className="mt-3 text-center"
          />

          {formData.isCorporate && (
            <>
              <Form.Group controlId="companyTitle" className="mt-3">
                <Form.Label>Ünvan</Form.Label>
                <Form.Control
                  type="text"
                  name="companyTitle"
                  value={formData.companyTitle}
                  onChange={handleChange}
                  className="text-center"
                />
              </Form.Group>

              <Form.Group controlId="taxNumber" className="mt-3">
                <Form.Label>Vergi veya TC. No</Form.Label>
                <Form.Control
                  type="text"
                  name="taxNumber"
                  value={formData.taxNumber}
                  onChange={handleChange}
                  className="text-center"
                />
              </Form.Group>

              <Form.Group controlId="taxOffice" className="mt-3">
                <Form.Label>Vergi Dairesi</Form.Label>
                <Form.Control
                  type="text"
                  name="taxOffice"
                  value={formData.taxOffice}
                  onChange={handleChange}
                  className="text-center"
                />
              </Form.Group>
            </>
          )}

          <Button type="submit" variant="warning" className="mt-3 w-100 text-center">
            Kaydet
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default InvoiceForm;
