import React from 'react';
import { Container, Row, Col, Dropdown, DropdownButton, OverlayTrigger, Popover, Table, Button } from 'react-bootstrap';
import { FaUser, FaBell, FaWhatsapp, FaWallet, FaBalanceScale, FaMapMarkerAlt, FaTruck, FaFileAlt } from 'react-icons/fa';
import { TbWorldShare } from 'react-icons/tb';

const Home = () => {
  const popover = (
    <Popover
      id="popover-help"
      style={{
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: '15px',
        width: '250px',
      }}
    >
      <Popover.Header
        as="h3"
        style={{
          borderBottom: 'none',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
      >
        Yardım
      </Popover.Header>
      <Popover.Body>
        <div style={{ textAlign: 'center', fontSize: '14px' }}>
          Bizimle <FaWhatsapp style={{ color: '#25D366' }} /> WhatsApp üzerinden <br />
          <strong style={{ color: 'red' }}>0850 304 0 555</strong> <br />
          numarası ile iletişime geçebilirsiniz.
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container fluid>
      {/* Header Section */}
      {/* <Row className="align-items-center py-2" style={{ borderBottom: '1px solid #ddd' }}>
        <Col xs={6} md={6} className="text-start">
          <h5 className="mb-0">ANA SAYFA</h5>
        </Col>
        <Col xs={6} md={6} className="d-flex align-items-center justify-content-end">
          <a href="/profile" className="me-2 text-dark">
            <FaUser size={20} />
          </a>
          <a href="/notifications" className="me-2 text-dark">
            <FaBell size={20} />
          </a>
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
            <span
              style={{
                color: '#000',
                textDecoration: 'none',
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '5px 10px',
                cursor: 'pointer',
              }}
              className="me-2"
            >
              Yardım ?
            </span>
          </OverlayTrigger>
          <DropdownButton id="dropdown-basic-button" title="+ Oluştur" variant="warning">
            <Dropdown.Item href="/kargo-olustur">Kargo Oluştur</Dropdown.Item>
            <Dropdown.Item href="/efatura-olustur">E-Fatura Oluştur</Dropdown.Item>
            <Dropdown.Item href="/yeni-musteri-ekle">Yeni Müşteri Ekle</Dropdown.Item>
            <Dropdown.Item href="/yurtdisi-kargo-olustur">Yurtdışı Kargo Oluştur</Dropdown.Item>
            <Dropdown.Item href="/link-ile-odeme-al">Link ile Ödeme Al</Dropdown.Item>
            <Dropdown.Item href="/kapida-odemeli-sat">Kapıda Ödemeli Sat</Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row> */}

      {/* Main Content Section */}
      <Row className="my-3">
        <Col md={6} className="d-flex">
          <div
            style={{
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '20px',
              backgroundColor: '#f8f9fa',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Row className="align-items-center">
              <Col xs={3} className="text-center">
                <FaWallet size={40} style={{ color: '#4E5969' }} />
              </Col>
              <Col xs={9}>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  Toplam Bakiye : {/* Bu veri API ile dinamik olarak güncellenecek */}
                </div>
                <div>
                  <a href="/bakiye-islemleri" style={{ color: '#FFC107', fontSize: '12px' }}>
                    BAKİYE YÜKLE / ÇEK
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6} className="d-flex">
          <div
            style={{
              border: '1px solid #ddd',
              borderRadius: '10px',
              padding: '20px',
              backgroundColor: '#f8f9fa',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <div style={{ fontSize: '14px', color: '#333' }}>
              <div>Gönderi ortalaması: {/* API ile çekilecek */}</div>
              <div>Bakiye: {/* API ile çekilecek */}</div>
              <div>Bloklanan Bakiye: {/* API ile çekilecek */}</div>
              <div>Kullanılabilir Bakiye: {/* API ile çekilecek */}</div>
              <div>
                <a href="/tum-hareketler" style={{ color: '#FFC107', fontSize: '12px' }}>
                  TÜM HAREKETLER
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Full Border Between Sections */}
      <hr style={{ border: '1px solid #ddd', margin: '20px 0' }} />

      {/* Son 3 Kargo Tablosu */}
      <Row className="my-3">
        <Col md={12}>
          <div style={{ border: '1px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#f8f9fa' }}>
            <h6 style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '20px' }}>SON 3 KARGO</h6>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>
                    <FaUser style={{ marginRight: '5px' }} /> Alıcı
                  </th>
                  <th style={{ width: '50%' }}>
                    <FaMapMarkerAlt style={{ marginRight: '5px' }} /> Durum
                  </th>
                  <th style={{ width: '15%' }}>
                    <FaBalanceScale style={{ marginRight: '5px' }} /> Desi/Kg
                  </th>
                  <th style={{ width: '10%' }}>Tutar</th>
                </tr>
              </thead>
              <tbody>
                {/* API ile veriler buraya gelecek */}
              </tbody>
            </Table>
            <Button
              variant="warning"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '25%', // Genişlik %25
                padding: '10px',
                borderRadius: '5px',
                marginTop: '10px',
                fontWeight: 'bold',
                float: 'right' // Sağa yaslamak için
              }}
              href="/kargo-olustur"
            >
              <FaTruck style={{ marginRight: '10px' }} />
              YENİ KARGO OLUŞTUR
            </Button>
          </div>
        </Col>
      </Row>

      {/* Full Border Between Sections */}
      <hr style={{ border: '1px solid #ddd', margin: '20px 0' }} />

      {/* Son 3 E-Fatura Tablosu */}
      <Row className="my-3">
        <Col md={12}>
          <div style={{ border: '1px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#f8f9fa' }}>
            <h6 style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '20px' }}>SON 3 E-FATURA</h6>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>
                    <FaUser style={{ marginRight: '5px' }} /> Alıcı
                  </th>
                  <th style={{ width: '50%' }}>
                    <FaFileAlt style={{ marginRight: '5px' }} /> Fatura No
                  </th>
                  <th style={{ width: '15%' }}>
                    <FaBalanceScale style={{ marginRight: '5px' }} /> Tutar
                  </th>
                  <th style={{ width: '10%' }}>Tarih</th>
                </tr>
              </thead>
              <tbody>
                {/* API ile veriler buraya gelecek */}
              </tbody>
            </Table>
            <Button
              variant="warning"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '25%', // Genişlik %25
                padding: '10px',
                borderRadius: '5px',
                marginTop: '10px',
                fontWeight: 'bold',
                float: 'right' // Sağa yaslamak için
              }}
              href="/efatura-olustur"
            >
              <FaFileAlt style={{ marginRight: '10px' }} />
              YENİ E-FATURA OLUŞTUR
            </Button>
          </div>
        </Col>
      </Row>

      {/* Full Border Between Sections */}
      <hr style={{ border: '1px solid #ddd', margin: '20px 0' }} />

      {/* Son 3 İhracat Tablosu */}
      <Row className="my-3">
        <Col md={12}>
          <div style={{ border: '1px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#f8f9fa' }}>
            <h6 style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '20px' }}>SON 3 İHRACAT</h6>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>
                    <FaUser style={{ marginRight: '5px' }} /> Alıcı
                  </th>
                  <th style={{ width: '50%' }}>
                    <FaMapMarkerAlt style={{ marginRight: '5px' }} /> Durum
                  </th>
                  <th style={{ width: '15%' }}>
                    <FaBalanceScale style={{ marginRight: '5px' }} /> Desi/Kg
                  </th>
                  <th style={{ width: '10%' }}>Tutar</th>
                </tr>
              </thead>
              <tbody>
                {/* API ile veriler buraya gelecek */}
              </tbody>
            </Table>
            <Button
              variant="warning"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '25%', // Genişlik %25
                padding: '10px',
                borderRadius: '5px',
                marginTop: '10px',
                fontWeight: 'bold',
                float: 'right' // Sağa yaslamak için
              }}
              href="/yurtdisi-kargo-olustur"
            >
              <TbWorldShare style={{ marginRight: '10px' }} />
              YENİ İHRACAT OLUŞTUR
            </Button>
          </div>
        </Col>
      </Row>

    </Container>
  );
};

export default Home;
