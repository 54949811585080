import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL; // Dinamik API URL'sini alıyoruz

const Account = () => {
  const [user, setUser] = useState({ firstName: '', lastName: '', profileImage: '' });
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${apiUrl}/user`, { // Dinamik URL
          headers: { 'x-auth-token': token }
        });
        setUser(response.data);
      } catch (error) {
        console.error('Kullanıcı verileri alınırken bir hata oluştu:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${apiUrl}/auth/logout`, {}, { // Dinamik URL
        headers: { 'x-auth-token': token }
      });
      localStorage.removeItem('token'); // Token'ı localStorage'dan sil
      navigate('/'); // Kullanıcıyı ana sayfaya yönlendir
    } catch (error) {
      console.error('Çıkış yapılırken bir hata oluştu:', error);
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={6} className="mx-auto">
          <div 
            className="text-center p-4" 
            style={{
              backgroundColor: 'white', 
              borderRadius: '10px', 
              color: '#FFF',
              position: 'relative',
              padding: '40px',
            }}
          >
            <div className="profile-avatar mb-4" style={{ position: 'relative', textAlign: 'center' }}>
              <label 
                htmlFor="image-upload" 
                style={{
                  cursor: 'pointer', 
                  display: 'inline-block', 
                  borderRadius: '50%', 
                  overflow: 'hidden',
                  width: '120px', 
                  height: '120px',
                  backgroundColor: '#FFD700',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}
              >
                {imagePreview ? (
                  <img 
                    src={imagePreview} 
                    alt={`${user.firstName} ${user.lastName}`} 
                    className="rounded-circle" 
                    style={{ width: '120px', height: '120px', objectFit: 'cover' }}
                  />
                ) : (
                  <div 
                    className="d-flex align-items-center justify-content-center"
                    style={{ 
                      width: '120px', 
                      height: '120px', 
                      fontSize: '36px',
                      fontWeight: 'bold',
                      color: 'black'
                    }}>
                    {`${user.firstName[0]}${user.lastName[0]}`}
                  </div>
                )}
                <input 
                  id="image-upload" 
                  type="file" 
                  accept="image/*" 
                  onChange={handleImageUpload} 
                  style={{ display: 'none' }}
                />
              </label>
              <h4 className="mt-3 text-dark">{`${user.firstName} ${user.lastName}`}</h4>
            </div>

            <div className="button-group">
              <Link to="/panelim/profile">
                <Button variant="warning" className="w-100 mb-3">PROFİLİM</Button>
              </Link>

              <Link to="/panelim/adreslerim">
                <Button variant="warning" className="w-100 mb-3">ADRESLERİM</Button>
              </Link>
              <Link to="/panelim/sözleşmelerim">
                <Button variant="warning" className="w-100 mb-3">SÖZLEŞMELERİM</Button>
              </Link>
            </div>
            <Button variant="outline-dark" className="w-100 mt-4" onClick={handleLogout}>Çıkış Yap</Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Account;
