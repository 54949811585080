import React, { useState, useEffect } from 'react';
import { Button, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsInfoCircle } from 'react-icons/bs';
import HavaleEftOffcanvas from './HavaleEftOffcanvas';
import axios from 'axios';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';

const BalanceLoad = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [usableBalance, setUsableBalance] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [accountName, setAccountName] = useState('');

  const userId = useSelector((state) => state.user.userId);

  const [cardDetails, setCardDetails] = useState({
    name: '',
    number: '',
    expiryMonth: '',
    expiryYear: '',
    cvc: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const balanceResponse = await axios.get(`/api/wallet/${userId}/balance`);
        setUsableBalance(balanceResponse.data.usableBalance);
      } catch (error) {
        console.error('Veriler alınırken bir hata oluştu:', error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  const handlePaymentMethodChange = (e) => {
    setSelectedPaymentMethod(e.target.id);
    if (e.target.id === 'havale-eft') {
      setShowOffcanvas(true);
    } else {
      setShowOffcanvas(false);
    }
  };
const handleDeposit = async () => {
  try {
    // Ödeme talebini backend'e gönder
    const response = await axios.post('/api/wallet/deposit', {
      amount: depositAmount,
      cardDetails
    });

    if (response.data.success) {
      // Ödeme başarılı olduysa, kullanıcının bakiyesini güncelle
      const balanceResponse = await axios.get(`/api/wallet/${userId}/balance`);
      setUsableBalance(balanceResponse.data.usableBalance);
      alert('Ödeme başarılı!'); // Kullanıcıya geri bildirim ver
    } else {
      console.error('Ödeme işlemi başarısız:', response.data.error);
      alert('Ödeme başarısız! Lütfen bilgilerinizi kontrol edin.'); // Hata durumunda geri bildirim ver
    }
  } catch (error) {
    console.error('Para yükleme işlemi sırasında bir hata oluştu:', error);
    alert('Bir hata oluştu! Lütfen daha sonra tekrar deneyin.'); // Hata durumunda geri bildirim ver
  }
};


  const handleWithdraw = async () => {
    try {
      await axios.post('/api/wallet/withdraw', {
        amount: withdrawAmount,
        owner_id: userId,
        accountName: accountName,
        payment_method: 'Bank Transfer'
      });

      const balanceResponse = await axios.get(`/api/wallet/${userId}/balance`);
      setUsableBalance(balanceResponse.data.usableBalance);
    } catch (error) {
      console.error('Para çekme işlemi sırasında bir hata oluştu:', error);
    }
  };

  const validateExpiryDate = () => {
    const month = parseInt(cardDetails.expiryMonth, 10);
    const year = parseInt(cardDetails.expiryYear, 10);

    if (month < 1 || month > 12 || year < new Date().getFullYear() || (year === new Date().getFullYear() && month < new Date().getMonth() + 1)) {
      alert('Lütfen geçerli bir tarih girin');
      setCardDetails({ ...cardDetails, expiryMonth: '', expiryYear: '' });
    }
  };

  return (
    <>
      <Card className="p-3">
        <h5 className="text-center mb-4">KULLANILABİLİR BAKİYE</h5>
        <h2 className="text-center mb-4">{usableBalance.toFixed(2)}₺</h2>
        
        <Card className="mb-3">
          <Card.Header className="text-center">BAKİYE YÜKLE</Card.Header>
          <Card.Body>
            <Form.Group className="mb-3" controlId="formLoadAmount">
              <Form.Label className="w-100 text-center">Yüklenecek Tutar ₺</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tutar girin"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-center">
              <div style={{ display: 'inline-block', textAlign: 'left' }}>
                <Form.Check 
                  type="radio" 
                  label="Kredi / Banka Kartı" 
                  name="paymentMethod" 
                  id="kredi-banka-karti"
                  onChange={handlePaymentMethodChange} 
                />
                <Form.Check 
                  type="radio" 
                  label="Havale / EFT" 
                  name="paymentMethod" 
                  id="havale-eft"
                  onChange={handlePaymentMethodChange} 
                />
              </div>
            </Form.Group>

            {selectedPaymentMethod === 'kredi-banka-karti' && (
              <>
                <Form.Group className="mb-3 text-center">
                  <Form.Label className="w-100"></Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Kart Sahibi Adı"
                    value={cardDetails.name}
                    onChange={(e) => setCardDetails({...cardDetails, name: e.target.value})}
                    style={{ textAlign: 'center' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3 text-center">
                  <Form.Label className="w-100"></Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="Kart Numarası"
                    value={cardDetails.number}
                    onChange={(e) => setCardDetails({...cardDetails, number: e.target.value})}
                    style={{ textAlign: 'center' }}
                  />
                </Form.Group>
                <Form.Group className="mb-3 text-center">
                  <Form.Label className="w-100">Son Kullanım Tarihi</Form.Label>
                  <InputMask
                    mask="99/99"
                    placeholder="AA/YY"
                    value={`${cardDetails.expiryMonth}/${cardDetails.expiryYear}`}
                    onChange={(e) => setCardDetails({
                      ...cardDetails,
                      expiryMonth: e.target.value.split('/')[0],
                      expiryYear: e.target.value.split('/')[1]
                    })}
                    onBlur={validateExpiryDate}
                    maskPlaceholder={null}
                  >
                    {(inputProps) => <Form.Control {...inputProps} style={{ textAlign: 'center' }} />}
                  </InputMask>
                </Form.Group>
                <Form.Group className="mb-3 text-center">
                  <Form.Label className="w-100"></Form.Label>
                  <Form.Control 
                    type="text" 
                    placeholder="CVC"
                    value={cardDetails.cvc}
                    onChange={(e) => setCardDetails({...cardDetails, cvc: e.target.value})}
                    style={{ textAlign: 'center' }}
                  />
                </Form.Group>
              </>
            )}

            <Button variant="warning" className="w-100" onClick={handleDeposit}>Ödeme Yap</Button>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>
            PARA ÇEK
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip">
                  15:00 Saatine kadar oluşturduğunuz para çekim talepleri aynı gün, 15:00 saatinden sonrasında oluşturduğunuz para çekim talepleri, ertesi gün 15:00 saatinden sonrasında hesabınıza aktarılır.
                </Tooltip>
              }
            >
              <span style={{ marginLeft: '8px', cursor: 'pointer', color: '#007bff' }}>
                Param ne zaman yatar? <BsInfoCircle size={20} />
              </span>
            </OverlayTrigger>
          </Card.Header>
          <Card.Body>
            <Form.Group className="mb-3 text-center">
              <Form.Label>Çekilecek Tutar ₺</Form.Label>
              <Form.Control
                type="text"
                placeholder="Tutar girin"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-center">
              <Form.Label>AKTARILACAK HESAP</Form.Label>
              <Form.Control 
                type="text" 
                placeholder=""
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Button variant="warning" className="w-100 mt-4" onClick={handleWithdraw}>Çekim Yap</Button>
          </Card.Body>
        </Card>
      </Card>

      <HavaleEftOffcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} />
    </>
  );
};

export default BalanceLoad;
