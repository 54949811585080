import React, { useState, useEffect } from 'react';
import { Offcanvas, Card, Form, Button, Accordion, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';

const HavaleEftOffcanvas = ({ show, onHide }) => {
  const [usableBalance, setUsableBalance] = useState(0);
  const [banks, setBanks] = useState([]);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [accountName, setAccountName] = useState('');
  const [depositAmount, setDepositAmount] = useState('');

  const userId = useSelector((state) => state.user.userId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const balanceResponse = await axios.get(`/api/wallet/${userId}/balance`);
        setUsableBalance(balanceResponse.data.usableBalance);

        const banksResponse = await axios.get(`/api/wallet/${userId}/banks`);
        setBanks(banksResponse.data.banks);
      } catch (error) {
        console.error('Veriler alınırken bir hata oluştu:', error);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  const handleDeposit = async () => {
    try {
      await axios.post('/api/wallet/deposit/bank-transfer', {
        amount: depositAmount,
        bankAccountId: 'banka_hesap_id',
        transactionId: 'islem_numarasi'
      });
      const response = await axios.get(`/api/wallet/${userId}/balance`);
      setUsableBalance(response.data.usableBalance);
    } catch (error) {
      console.error('Havale/EFT bildirimi sırasında bir hata oluştu:', error);
    }
  };

  const handleWithdraw = async () => {
    try {
      await axios.post('/api/wallet/withdraw', {
        amount: withdrawAmount,
        accountName: accountName,
      });
      const response = await axios.get(`/api/wallet/${userId}/balance`);
      setUsableBalance(response.data.usableBalance);
    } catch (error) {
      console.error('Para çekme işlemi sırasında bir hata oluştu:', error);
    }
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="w-100 text-center">Havale / EFT Bilgileri</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Card className="text-center mb-3">
          <Card.Body>
            <h2>{usableBalance.toFixed(2)}₺</h2>
            <p>KULLANILABİLİR BAKİYE</p>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Header className="text-center">BAKİYE YÜKLE</Card.Header>
          <Card.Body>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="w-100">Yüklenecek Tutar ₺</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Yüklenecek Tutarı Giriniz"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Button variant="warning" className="w-100 mt-3" onClick={handleDeposit}>
              HAVALE/EFT BİLDİR
            </Button>
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Body className="text-center">
            <p className="mb-0"><strong>WAL39913569</strong> işlem numarasını açıklamaya ekleyerek aşağıdaki banka hesaplarımızdan dilediğinize Havale/EFT işlemi yapabilirsiniz.</p>
          </Card.Body>
        </Card>

        <Accordion className="mb-3">
          {banks.map((bank, index) => (
            <Accordion.Item eventKey={index.toString()} key={bank.iban}>
              <Accordion.Header>
                <Row className="w-100 justify-content-center">
                  <Col className="text-center">
                    <img src={bank.image} alt={bank.name} style={{ width: '70px' }} />
                  </Col>
                </Row>
              </Accordion.Header>
              <Accordion.Body className="text-center">
                <p>HESAP ADI: {bank.accountName}</p>
                <p>IBAN: {bank.iban}</p>
                <Button variant="warning" className="w-100" onClick={() => navigator.clipboard.writeText(bank.iban)}>Kopyala</Button>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>

        <Card>
          <Card.Header className="text-center">PARA ÇEK</Card.Header>
          <Card.Body>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="w-100">Çekilecek Tutar ₺</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Tutar girin"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-center">
              <Form.Label className="w-100">AKTARILACAK HESAP</Form.Label>
              <Form.Control 
                type="text" 
                placeholder=""
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Button variant="warning" className="w-100 mt-4" onClick={handleWithdraw}>Çekim Yap</Button>
          </Card.Body>
        </Card>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default HavaleEftOffcanvas;
