import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
import axios from 'axios';

const AccountDeactivation = () => {
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [password, setPassword] = useState('');
  const [deactivationReason, setDeactivationReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [deactivationStatus, setDeactivationStatus] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleReasonChange = (e) => {
    const value = e.target.value;
    if (value === 'Diğer') {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
      setDeactivationReason(value);
    }
  };
const handleDeactivation = async () => {
  console.log('API URL:', `${apiUrl}/auth/restrict-account`); // Düzeltilmiş URL'yi loglayalım
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(
      `${apiUrl}/auth/restrict-account`,  // /api/ olmadan
      {
        password,
        reason: showOtherInput ? otherReason : deactivationReason,
      },
      {
        headers: { 'x-auth-token': token },
      }
    );
    if (response.data) {
      setDeactivationStatus('Hesabınız başarıyla kısıtlandı.');
    }
  } catch (error) {
    console.error('Hesap kısıtlanırken bir hata oluştu:', error);
    setDeactivationStatus('Hesap kısıtlanırken bir hata oluştu.');
  }
};



  return (
    <Container fluid className="py-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="p-4 shadow-sm">
            <Card.Body>
              <h5 className="text-center mb-4">HESAP KAPATMA</h5>
              <Alert variant="light" className="text-center">
                <i className="bi bi-info-circle" style={{ fontSize: '24px' }}></i>
                <p className="mb-1 mt-2"><strong>Hesabınızı Kapattığınızda:</strong></p>
                <ul className="text-start ps-4 mb-2">
                  <li><span className="text-warning">Kargolarınız</span></li>
                  <li><span className="text-warning">Faturalarınız</span></li>
                  <li><span className="text-warning">Kayıtlı Müşterileriniz</span></li>
                  <li><span className="text-warning">Cüzdan Hareketleriniz</span></li>
                </ul>
                <p className="text-muted">
                  Bu verilere ulaşamayacaksınız. Mağduriyet yaşamamanız adına,
                  ilgili verileri çıktı al seçeneği ile cihazınıza indirmenizi
                  ve saklamanızı tavsiye ederiz.
                </p>
              </Alert>

              <Form.Group className="mb-4">
                <Form.Label className="fw-bold">Mevcut Şifreniz</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Şifrenizi girin"
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label className="fw-bold text-center w-100">Hesabınızı kapatma nedeniniz?</Form.Label>
                <p className="text-muted text-center mb-3">
                  Tüm Kullanıcılarımız Gibi Siz ve Görüşleriniz Bizim İçin Çok Değerli!
                  Hesabınızı Kapatma nedeninizi belirtmenizi rica ederiz.
                </p>
                <div>
                  <Form.Check
                    type="radio"
                    label="Uygulamayı Kullanışlı Bulmadım"
                    name="reason"
                    value="Uygulamayı Kullanışlı Bulmadım"
                    onChange={handleReasonChange}
                    className="mb-2"
                  />
                  <Form.Check
                    type="radio"
                    label="Kargo Fiyatlarınız Yüksek"
                    name="reason"
                    value="Kargo Fiyatlarınız Yüksek"
                    onChange={handleReasonChange}
                    className="mb-2"
                  />
                  <Form.Check
                    type="radio"
                    label="Hizmet Bedelleriniz Yüksek"
                    name="reason"
                    value="Hizmet Bedelleriniz Yüksek"
                    onChange={handleReasonChange}
                    className="mb-2"
                  />
                  <Form.Check
                    type="radio"
                    label="Diğer"
                    name="reason"
                    value="Diğer"
                    onChange={handleReasonChange}
                    className="mb-2"
                  />
                  {showOtherInput && (
                    <Form.Control
                      type="text"
                      placeholder="Diğer sebebinizi belirtin"
                      className="mt-2"
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                    />
                  )}
                </div>
              </Form.Group>

              {deactivationStatus && (
                <Alert variant="danger" className="mb-4">
                  {deactivationStatus}
                </Alert>
              )}

              <Button
                variant="warning"
                className="w-100 fw-bold text-white"
                style={{ backgroundColor: '#FFD700' }}
                onClick={handleDeactivation}
              >
                Hesabımı Kapat
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountDeactivation;
