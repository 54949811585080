import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard'; // Sidebar menü ve içerik
import Home from './Home'; // Ana Sayfa bileşeni
import Account from './Account'; // Hesabım bileşeni
import Shipments from './Shipments'; // Gönderiler bileşeni
import Invoices from './Invoices'; // Faturalarım bileşeni
import Wallet from './Wallet'; // Cüzdanım bileşeni
import Customers from './Customers'; // Müşterilerim bileşeni
import Payments from './Payments'; // Online Ödemeler bileşeni
import Export from './Export'; // İhracat bileşeni
import Integration from './Integration'; // API Entegrasyon bileşeni
import Profile from './Account/Profile';
import Address from './Account/Address';
import Contract  from './Account/Contract';
import Remove from './Account/Remove';
import ManuelShipments from './Shipments/ManuelShipments';
import MeTo from './Shipments/Meto'


function DashboardRouter() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Home />} />
        <Route path="account" element={<Account />} />
        <Route path="shipments" element={<Shipments />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="customers" element={<Customers />} />
        <Route path="payments" element={<Payments />} />
        <Route path="export" element={<Export />} />
        <Route path="integration" element={<Integration />} />
         <Route path="profile" element={<Profile />} /> 
         <Route path="adreslerim" element={<Address />} /> 
           <Route path="sözleşmelerim" element={<Contract />} /> 
            <Route path="kaldır" element={<Remove />} /> 
            <Route path="manuel-ekle" element={<ManuelShipments />} /> 
             <Route path="bana-gelecek" element={<MeTo />} />

      </Route>
    </Routes>
  );
}

export default DashboardRouter;
