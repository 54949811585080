import React, { useState } from 'react';
import { Row, Col, Form, Button, Offcanvas } from 'react-bootstrap';
import axios from 'axios';

const AddProducts = ({ show, handleClose, handleSubmit, addProductToList }) => {
  const [formData, setFormData] = useState({
    name: '',
    price: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      // Ürün verisini backend'e gönderme işlemini yapabiliriz veya sadece frontend'de listeleyebiliriz.
      addProductToList(formData); // Yeni ürünü parent component'e gönderiyoruz.
      handleClose(); // Offcanvas'ı kapatıyoruz.
    } catch (error) {
      console.error('Ürün eklenirken bir hata oluştu:', error);
      alert('Ürün eklenemedi, lütfen tekrar deneyin.');
    }
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>YENİ ÜRÜN EKLE</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Ürün Adı</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="price" className="mt-3">
            <Form.Label>Ürün Fiyatı (₺)</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Button variant="warning" type="submit" className="mt-3 w-100">
            KAYDET
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddProducts;
