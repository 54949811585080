import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Accordion, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AddAddress from './AddAddress';
import AddProducts from './AddProducts';
import OnlinePayment from './OnlinePayment';
import Cargos from './Cargos';
import DistanceContract from './DistanceContract';  // Importing DistanceContract
import InvoiceForm from './InvoiceForm';

const ManuelShipments = () => {
  const [addresses, setAddresses] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [products, setProducts] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0); // Ürünlerin toplam fiyatı
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showCustomerOffcanvas, setShowCustomerOffcanvas] = useState(false);
  const [showProductOffcanvas, setShowProductOffcanvas] = useState(false);
  const [showDistanceContract, setShowDistanceContract] = useState(false); // For DistanceContract
  const [showInvoiceForm, setShowInvoiceForm] = useState(false);
  const [formData, setFormData] = useState({
    eInvoice: false,
    sendEmail: false,
    sendSMS: false,
    insurance: false,
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  // Backend'den adresleri çekme
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/addresses`);
        setAddresses(response.data);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };
    fetchAddresses();
  }, []);

  // Adres silme işlemi
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/addresses/${id}`);
      setAddresses(addresses.filter((address) => address.id !== id));
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  // Adres ekleme işlemi
  const handleAddAddress = async (newAddress) => {
    try {
      const response = await axios.post(`${apiUrl}/addresses`, newAddress);
      setAddresses([...addresses, response.data]);
    } catch (error) {
      console.error('Error adding address:', error);
    }
  };

  // Müşteri adresi ekleme işlemi
  const handleAddCustomerAddress = async (newCustomerAddress) => {
    try {
      const response = await axios.post(`${apiUrl}/customer-addresses`, newCustomerAddress);
      setCustomerAddresses([...customerAddresses, response.data]);
    } catch (error) {
      console.error('Error adding customer address:', error);
    }
  };

  // Ürün ekleme ve toplam tutarı güncelleme işlemi
  const handleAddProduct = (newProduct) => {
    setProducts([...products, newProduct]);
    setTotalAmount((prevTotal) => prevTotal + parseFloat(newProduct.price)); // Toplam tutarı güncelle
  };

  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  const handleShowCustomerOffcanvas = () => setShowCustomerOffcanvas(true);
  const handleCloseCustomerOffcanvas = () => setShowCustomerOffcanvas(false);

  const handleShowProductOffcanvas = () => setShowProductOffcanvas(true);
  const handleCloseProductOffcanvas = () => setShowProductOffcanvas(false);

  const handleShowDistanceContract = () => setShowDistanceContract(true);  // Show DistanceContract
  const handleCloseDistanceContract = () => setShowDistanceContract(false);  // Close DistanceContract

  const handleShowInvoiceForm = () => setShowInvoiceForm(true);
  const handleCloseInvoiceForm = () => setShowInvoiceForm(false);

  // Form değerlerini güncelleme
  const handleFormChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  // Form gönderme işlemi
  const handleSubmit = async (e) => {
    e.preventDefault();

    const customerEmail = customerAddresses.length > 0 ? customerAddresses[0].email : null;

    // ManuelShipments formundan gelen veriler
    const submissionData = {
      eInvoice: formData.eInvoice,
      sendEmail: formData.sendEmail,
      sendSMS: formData.sendSMS,
      insurance: formData.insurance,
      senderAddresses: addresses,
      recipientAddresses: customerAddresses,
      products: products,
      total_amount: totalAmount, // Toplam tutar
    };

    try {
      // Kargo oluşturma isteği gönderiliyor
      const response = await axios.post(`${apiUrl}/shipments/create`, submissionData);

      // Eğer trackingCode yoksa hata mesajı yazdırılır ve işlem durdurulur
      if (!response.data.trackingCode) {
        console.error('Tracking code alınamadı.');
        alert('Kargo oluşturulamadı, tracking code alınamadı.');
        return; // E-posta gönderimi yapılmadan çıkılır
      }

      // Eğer "sendEmail" işaretlenmişse ve müşteri e-posta adresi varsa e-posta gönder
      if (formData.sendEmail && customerEmail) {
        const emailData = {
          customerEmail: customerEmail,
          customerName: `${customerAddresses[0].firstName} ${customerAddresses[0].lastName}`,
          trackingCode: response.data.trackingCode,  // Oluşturulan kargo için takip kodu
        };

        await axios.post(`${apiUrl}/send-email`, emailData);
      }

      alert(`Kargo başarıyla oluşturuldu! Kargo ID: ${response.data.id}`);
    } catch (error) {
      console.error('Kargo oluşturulamadı:', error);
    }
  };

  return (
    <Container fluid>
      {/* "KARGO BANA GELECEK" butonu */}
      <Row className="mb-3">
        <Col>
          <Link to="/panelim/bana-gelecek" className="btn btn-warning w-100">
            KARGO BANA GELECEK
          </Link>
        </Col>
      </Row>

      {/* Gönderici Adresi Listesi */}
      <Card className="mb-3">
        <Card.Header>Gönderici Adresi Seç, Ekle</Card.Header>
        <Card.Body>
          <Accordion defaultActiveKey="0">
            {addresses.length > 0 ? (
              addresses.map((address, index) => (
                <Accordion.Item eventKey={index.toString()} key={address.id}>
                  <Accordion.Header>{address.title}</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      {address.street}, {address.city} / {address.district}
                      <br />
                      {address.phone}
                    </div>
                    <div className="mt-2">
                      <Button variant="link" className="text-warning p-0">DÜZENLE</Button> |
                      <Button
                        variant="link"
                        className="text-warning p-0"
                        onClick={() => handleDelete(address.id)}
                      >
                        SİL
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))
            ) : (
              <p>Henüz adres eklenmemiş.</p>
            )}
          </Accordion>

          <Button variant="link" className="text-warning" onClick={handleShowOffcanvas}>
            + YENİ ADRES EKLE
          </Button>
        </Card.Body>
      </Card>

      {/* Alıcı Adresi Listesi */}
      <Card className="mb-3">
        <Card.Header>Alıcı Adresi Seç, Ekle</Card.Header>
        <Card.Body>
          <Accordion defaultActiveKey="0">
            {customerAddresses.length > 0 ? (
              customerAddresses.map((address, index) => (
                <Accordion.Item eventKey={index.toString()} key={address.id}>
                  <Accordion.Header>{address.title}</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      {address.street}, {address.city} / {address.district}
                      <br />
                      {address.phone}
                    </div>
                    <div className="mt-2">
                      <Button variant="link" className="text-warning p-0">DÜZENLE</Button> |
                      <Button
                        variant="link"
                        className="text-warning p-0"
                        onClick={() => handleDelete(address.id)}
                      >
                        SİL
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))
            ) : (
              <p>Henüz müşteri adresi eklenmemiş.</p>
            )}
          </Accordion>

          <Button variant="link" className="text-warning" onClick={handleShowCustomerOffcanvas}>
            + YENİ ALICI ADRESİ EKLE
          </Button>
        </Card.Body>
      </Card>

    

      {/* Ürün Seç, Ekle Bölümü */}
      <Card className="mb-3">
        <Card.Header>Ürün Seç, Ekle</Card.Header>
        <Card.Body>
          <Accordion defaultActiveKey="0">
            {products.length > 0 ? (
              products.map((product, index) => (
                <Accordion.Item eventKey={index.toString()} key={product.id}>
                  <Accordion.Header>{product.name}</Accordion.Header>
                  <Accordion.Body>
                    <div>
                      Ürün Fiyatı: {product.price} ₺
                    </div>
                    <div className="mt-2">
                      <Button variant="link" className="text-warning p-0">DÜZENLE</Button> |
                      <Button
                        variant="link"
                        className="text-warning p-0"
                        onClick={() => handleDelete(product.id)}
                      >
                        SİL
                      </Button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))
            ) : (
              <p>Henüz ürün eklenmemiş.</p>
            )}
          </Accordion>

          <Button variant="link" className="text-warning" onClick={handleShowProductOffcanvas}>
            + YENİ ÜRÜN EKLE
          </Button>
        </Card.Body>
      </Card>

      {/* Ödeme Bilgilendirme Bölümü */}
      <Row className="mb-3">
        {/* <OnlinePayment /> */}
      </Row>
      <Row className="mb-3">
        <Cargos />
      </Row>

      {/* Formik yerine orijinal form */}
      <Card className="mb-3">
        <Card.Body className="text-center">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="eInvoice" className="d-flex justify-content-center">
              <Form.Check
                type="checkbox"
                label="E-Fatura Oluştur (+2,00₺)"
                name="eInvoice"
                checked={formData.eInvoice}
                onChange={handleFormChange}
              />
            </Form.Group>

            <Form.Group controlId="sendEmail" className="d-flex justify-content-center">
              <Form.Check
                type="checkbox"
                label="Alıcıya e-posta kargo takip URL'si gönder"
                name="sendEmail"
                checked={formData.sendEmail}
                onChange={handleFormChange}
              />
            </Form.Group>

            <Form.Group controlId="sendSMS" className="d-flex justify-content-center">
              <Form.Check
                type="checkbox"
                label="Alıcıya SMS ile Takip URL bildirimi gönder (+1,00₺)"
                name="sendSMS"
                checked={formData.sendSMS}
                onChange={handleFormChange}
              />
            </Form.Group>

            <Form.Group controlId="insurance" className="d-flex justify-content-center">
              {/* <Form.Check
                type="checkbox"
                label="Kargo İçeriğini Sigortala (+10,00₺)"
                name="insurance"
                checked={formData.insurance}
                onChange={handleFormChange}
              /> */}
            </Form.Group>
            <div></div>
              {/* Mesafeli Satış Sözleşmesi ve K.V.K.K Bilgilendirme */}
      <Card className="mb-7">
        <Card.Body>
          <p><i className="bi bi-info-circle"></i> K.V.K.K gereği 3. şahıslara sistem üzerinden göndereceğiniz bildirim için müşterinizin açık rızasını almak satıcı olarak tarafınızın yükümlülüğündedir.</p>
          <p>
            <i className="bi bi-info-circle"></i> E-ticaret Satışınıza istinaden gönderi yapıyorsanız, Dilerseniz <Button variant="link" className="text-warning p-0" onClick={handleShowDistanceContract}>Buradan</Button> Mesafeli satış sözleşmesine tek tuşla ulaşarak, müşterinize iletebilirsiniz.
          </p>
        </Card.Body>
      </Card>

            <Button variant="warning" type="submit" className="mt-3 w-100">
              Gönder
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {/* Add Sender Address Offcanvas */}
      <AddAddress show={showOffcanvas} handleClose={handleCloseOffcanvas} handleSubmit={handleAddAddress} />

      {/* Add Customer Address Offcanvas */}
      <AddAddress show={showCustomerOffcanvas} handleClose={handleCloseCustomerOffcanvas} handleSubmit={handleAddCustomerAddress} isCustomer={true} />

      {/* Add Product Offcanvas */}
      <AddProducts show={showProductOffcanvas} handleClose={handleCloseProductOffcanvas} handleSubmit={handleAddProduct} />

      {/* Distance Contract Offcanvas */}
      <DistanceContract show={showDistanceContract} handleClose={handleCloseDistanceContract} />

      {/* InvoiceForm Offcanvas */}
      <InvoiceForm show={showInvoiceForm} handleClose={handleCloseInvoiceForm} />
    </Container>
  );
};

export default ManuelShipments;
