import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Offcanvas } from 'react-bootstrap';
import axios from 'axios';

const AddressForm = ({ show, handleClose, handleSubmit, isCustomer = false }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    addressTitle: '',
    phone: '',
    email: '',
    city: '',
    district: '',
    fullAddress: '',
    taxNumber: '',
    taxOffice: '',
    isCorporate: false,
    isEInvoice: false,
  });

  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    axios.get('https://turkiyeapi.dev/api/v1/provinces')
      .then(response => {
        setCities(response.data.data);
      })
      .catch(error => {
        console.error('İl listesi alınamadı:', error);
      });
  }, []);

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setSelectedCity(cityId);
    setFormData({ ...formData, city: cityId });

    axios.get(`https://turkiyeapi.dev/api/v1/provinces/${cityId}`)
      .then(response => {
        setDistricts(response.data.data.districts);
      })
      .catch(error => {
        console.error('İlçe listesi alınamadı:', error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
    handleClose();
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{isCustomer ? 'ALICI ADRESİ EKLE' : 'YENİ ADRES EKLE'}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="firstName">
                <Form.Label>Ad</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastName">
                <Form.Label>Soyad</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          {!isCustomer && (
            <Form.Group controlId="addressTitle" className="mt-3">
              <Form.Label>Gönderici Ünvanı</Form.Label>
              <Form.Control
                type="text"
                name="addressTitle"
                value={formData.addressTitle}
                onChange={handleInputChange}
              />
              <Form.Text className="text-muted">
                Zorunlu değil, giriş yapmazsanız ad ve soyad bilgileri gönderici olarak paylaşılır.
              </Form.Text>
            </Form.Group>
          )}

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="phone">
                <Form.Label>Telefon No</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="email">
                <Form.Label>E-posta</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="city">
                <Form.Label>İl</Form.Label>
                <Form.Select
                  name="city"
                  value={formData.city}
                  onChange={handleCityChange}
                  required
                >
                  <option>İl Seçin</option>
                  {cities.map(city => (
                    <option key={city.id} value={city.id}>{city.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="district">
                <Form.Label>İlçe</Form.Label>
                <Form.Select
                  name="district"
                  value={formData.district}
                  onChange={handleInputChange}
                  required
                >
                  <option>İlçe Seçin</option>
                  {districts.map(district => (
                    <option key={district.id} value={district.id}>{district.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="fullAddress" className="mt-3">
            <Form.Label>Tam Adres</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="fullAddress"
              value={formData.fullAddress}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          <Form.Check
            type="checkbox"
            name="isCorporate"
            checked={formData.isCorporate}
            onChange={handleInputChange}
            label="Kurumsal Şirket bilgilerinizi eklemeye ihtiyacınız varsa işaretleyiniz."
            className="mt-3"
          />
          
          {formData.isCorporate && (
            <>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="taxNumber">
                    <Form.Label>Vergi veya TC. No</Form.Label>
                    <Form.Control
                      type="text"
                      name="taxNumber"
                      value={formData.taxNumber}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="taxOffice">
                    <Form.Label>Vergi Dairesi</Form.Label>
                    <Form.Control
                      type="text"
                      name="taxOffice"
                      value={formData.taxOffice}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Check
                type="checkbox"
                name="isEInvoice"
                checked={formData.isEInvoice}
                onChange={handleInputChange}
                label="E-Fatura Mükellefiyim"
                className="mt-3"
              />
            </>
          )}

          <Button variant="warning" type="submit" className="mt-3 w-100">
            KAYDET
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddressForm;
