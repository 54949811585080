import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaBuilding } from 'react-icons/fa';
import { AiOutlineGlobal } from 'react-icons/ai';
import  '../assets/css/Footer.css'

const Footer = () => {
  return (
    <footer className="footer-section text-light py-4" id='iletişim'>
      <Container>
        <Row className="mb-5">
          <Col xs={12} md={4} className="mb-4 mb-md-0 text-center text-md-start">
            <h5 className="text-warning mb-3 d-flex align-items-center justify-content-center justify-content-md-start">
              <AiOutlineGlobal className="me-2 text-warning" />
              İNDİRİMLİ KARGO
            </h5>
            <p style={{ color: '#f0ad4e', fontSize: '1rem', fontWeight: 'bold' }}>TEK TIKLA KARGO...</p>
            <ul className="list-unstyled">
              <li className="mb-2 d-flex align-items-center justify-content-center justify-content-md-start">
                <FaBuilding className="me-2 text-warning" />
                <small>Sanatardan E-Ticaret İnternet Mağazacılık San.Tic.Ltd.Şti.</small>
              </li>
              <li className="mb-2 d-flex align-items-center justify-content-center justify-content-md-start">
                <FaPhone className="me-2 text-warning" />
                <small>+90 850 304 0555</small>
              </li>
              <li className="mb-2 d-flex align-items-center justify-content-center justify-content-md-start">
                <FaEnvelope className="me-2 text-warning" />
                <small>destek@indirimlikargo.com</small>
              </li>
              <li className="d-flex align-items-center justify-content-center justify-content-md-start">
                <FaMapMarkerAlt className="me-2 text-warning" />
                <small>Kazımkarabekir Mh. Dumlupınar Blv. No:34/81 Çankaya ANKARA</small>
              </li>
            </ul>
            <p className="mt-4 text-center text-md-start">&copy; 2024 | All rights reserved by</p>
          </Col>

          <Col xs={12} md={4} className="mb-4 mb-md-0 text-center text-md-start">
            <h5 className="text-warning mb-3">YASAL LİNKLER</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <small>Kişisel Verilerin Korunması Politikası</small>
              </li>
              <li className="mb-2">
                <small>Kullanım Koşullarımız</small>
              </li>
              <li className="mb-2">
                <small>Aydınlatma ve Açık Rıza Metni</small>
              </li>
              <li className="mb-2">
                <small>Gizlilik ve Çerez Politikası</small>
              </li>
              <li className="mb-2">
                <small>Esnaf Vergi Muafiyeti</small>
              </li>
            </ul>
          </Col>

          <Col xs={12} md={4} className="text-center text-md-start">
            <h5 className="text-warning mb-3">HAKKIMIZDA</h5>
            <p>
              <small>
                Üretim ve bir çok ticari alanda 15 yılı aşkın süredir faaliyetler
                yürüttükten sonrasında, 2016 E-Ticaret sektöründe sanatardan.com
                alan adı üzerinde pazar yeri modeli ile hizmet vermeye başladık.
              </small>
            </p>
            <p>
              <small>
                Faaliyet yürüttüğümüz 8 yıla yakın sürede edindiğimiz bilgi ve
                tecrübe ışığında, hızla Dijitalleşen dünyada, ihtiyaç olan
                hizmetlerin farkındalığıyla, İndirimli Kargo Projesini hayata
                geçirerek, Kargo, Banka, E-fatura alanlarında yenilikçi, pratik ve
                ekonomik çözümler üreterek, siz değerli kullanıcıların hizmetine
                sunmuştur.
              </small>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
