import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import allLogos from '../../../assets/images/Kargo/api.png'; // Ortadaki logoların hepsini içeren resim

const SalesInfo = () => {
  return (
    <Container 
      fluid 
      className="p-4" 
      style={{ backgroundColor: '#1c1c1c', color: 'white', borderRadius: '10px', maxWidth: '100%', marginTop: '20px', textAlign: 'center' }}
    >
      <Row className="mb-3 justify-content-center">
        <Col xs="auto">
          <FaInfoCircle size={24} style={{ color: 'white' }} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h6>ONLINE PAZARYERLERİ VEYA KENDİ E-TİCARET SİTENDEN SATIŞ YAPIYORSAN!</h6>
        </Col>
      </Row>
      <Row className="mb-3 justify-content-center">
        <Col xs="auto">
          <Image src={allLogos} fluid style={{ maxWidth: '300px', height: 'auto' }} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <p style={{ fontWeight: 'bold' }}>TAMAMEN ÜCRETSİZ</p>
          <p>API - ENTEGRASYON HİZMETİMİZLE SATIŞ KANALLARINI BAĞLAYABİLİR</p>
          <p>KARGO VE E-FATURALARINI TEK TUŞLA KOLAYCA OLUŞTURUP TEK PANELDE ORGANİZE EDEBİLİRSİN</p>
        </Col>
      </Row>
      <Row className="mb-3 justify-content-center">
        <Col xs="auto">
          <Button variant="warning" size="lg">BAŞVUR</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SalesInfo;
