import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';

import axios from 'axios'; 
import logo from '../../assets/images/Kargo/indirimli.png';
import { useDispatch } from 'react-redux'; // Redux dispatch'i ekledik
import '../../assets/css/Login.css';

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
const apiUrl = process.env.REACT_APP_API_URL; 

  const dispatch = useDispatch(); // Redux dispatch hook'u



  const handleLogin = async (e) => {
  e.preventDefault();

  try {
  const response = await axios.post(`${apiUrl}/auth/login`, {
      email,
      password,
    });

    const data = response.data;
    console.log("Gelen data:", data);

  if (data.userId) {
   console.log("Redux'a kaydedilecek userId:", data.userId); 
   dispatch({ type: 'SET_USER', payload: { userId: data.userId } });
} else {
   console.error("userId sunucudan alınamadı!");
}


    localStorage.setItem('token', data.token);
    setIsLoggedIn(true);
    window.location.href = '/hoşgeldiniz';
  } catch (err) {
    console.error('Login error:', err.response?.data || err.message);
    setError('Geçersiz e-posta veya şifre.');
  }
};


  return (
    <Container fluid className="login-container d-flex align-items-center justify-content-center min-vh-100">
      <Row className="w-100">
        <h2 className="text-center mb-4 login-title">Giriş Yap</h2>
        <Col md={4} sm={8} xs={10} className="mx-auto p-4 login-box" style={{ maxWidth: '400px' }}>
          <div className="text-center mb-4">
            <div className="d-flex align-items-center justify-content-center">
              <Image src={logo} alt="İndirimli Kargo Logo" style={{ width: '40px' }} />
              <h4 className="mb-0 ms-2 text-warning">İNDİRİMLİ KARGO</h4>
            </div>
          </div>

          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Control 
                type="email" 
                placeholder="E - Posta Adresinizi Giriniz" 
                className="login-input" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Control 
                type="password" 
                placeholder="Parolanızı Giriniz" 
                className="login-input" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            {error && <p className="text-danger">{error}</p>}

            <Button type="submit" variant="warning" className="w-100 mb-3 login-button">
              GİRİŞ YAP
            </Button>

            {/* <div className="d-flex align-items-center mb-3">
              <hr className="flex-grow-1" />
              <span className="mx-2">YA DA</span>
              <hr className="flex-grow-1" />
            </div> */}

            {/* <Row>
              <Col>
                <Button variant="light" className="w-100 mb-2 social-button" onClick={handleGoogleLogin}>
                  <FcGoogle size={20} className="me-2" />
                  Google
                </Button>
              </Col>
            </Row> */}

            <p className="mt-4">Giriş Yap!</p>

            <p>
              Halen Kayıt Olmadın mı? <a href="/üye-ol" className="text-warning">Hemen Kayıt Ol!</a>
            </p>
            <p>
              <a href="/sifremi-unuttum" className="text-warning">Şifremi Unuttum!</a>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
