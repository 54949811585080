import React, { useState } from 'react';
import { Container, Row, Col, Button, Popover, OverlayTrigger, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaQuoteLeft } from 'react-icons/fa';
import '../assets/css/Services.css';
import '../assets/css/References.css';
import Image1 from '../assets/images/Kargo/indirimli.png';
import Image2 from '../assets/images/Kargo/indirimli.png';

const popoverContent = {
  kargo: 'Anlaşma, Taahhüt ve Sınır olmaksızın Sadece Sistemimize ücretsiz olarak kayıt olarak Dilediğiniz kargo firmasıyla %60 a varan indirimli fiyatlarla kargo gönderimi yapabilir tüm gönderilerinizi tek sayfada anlık takip edebilirsiniz.',
  efatura: 'Abonelik ücreti ödemeden ve toplu kontrol satın almak zorunda olmadan, tek tuşla e-faturalarınızı oluşturabilir tarafınıza gelen faturaları kaydederek hiçbir ek ücret ödemeden ön muhasebenizi tutabilirsiniz.',
  api: 'Online pazar yerlerinde veya kendi e-ticaret sitenizden satış yapıyorsanız API desteğimizle siparişlerinizi otomatik olarak sisteme aktarabilir tek tuşla kargo ve e-faturalarınızı oluşturabilirsiniz.',
  odeme: 'İhtiyacın olması halinde Tek tuşla Link ile online sipariş ödemesi alabilir veya Kapıda ödemeli satış ile müşteri güvenini ve satışlarını artırabilirsin.'
};

const PopoverBox = ({ title, content, activePopover, setActivePopover, id }) => {
  const handleClick = () => {
    setActivePopover(activePopover === id ? null : id);
  };

  const isActive = activePopover === id;
  const icon = isActive ? '-' : '+';

  return (
    <OverlayTrigger
      trigger="click"
      placement="auto"
      containerPadding={10}
      show={isActive}
      overlay={
        <Popover id={`popover-${id}`} placement="auto">
          <Popover.Body>{content}</Popover.Body>
        </Popover>
      }
    >
      <Button variant="link" className="text-decoration-none popover-box" onClick={handleClick}>
        <span className="d-flex justify-content-between align-items-center">
          {title} <span className="ml-auto">{icon}</span>
        </span>
      </Button>
    </OverlayTrigger>
  );
};

const Services = () => {
  const [activePopover, setActivePopover] = useState(null);

  return (
    <Container fluid id='main-container'>
      {/* Hizmetler Bölümü */}
      <Container fluid className="vh-80 d-flex flex-column" id='hizmetlerimiz' style={{ order: 1 }}>
        <Row className="flex-grow-1 flex-md-row flex-column">
          <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-center p-5">
            <div className="text-center">
              <h4 className="text-warning">HİZMETLERİMİZ</h4>
              <h1 className='text-light'>Kazandıran Çözümler.</h1>
              <p className=" text-light mt-4">
                Tüm tacir ve üreticilerin ihtiyaçlarına yönelik geliştirdiğimiz yenilikçi ve kullanımı kolay hizmetlerimizle işlerinizi kolaylaştırırken kazancınızı katlayın.
              </p>
              <div className="d-flex justify-content-center circle-button-container">
                <div className="circle-button">
                  <p className="mb-0">Bizi Arayın</p>
                  <p className="small-text mb-0">7/24</p>
                </div>
                <div className="circle-button">
                  <p className="mb-0">Bize Yazın</p>
                  <p className="small-text mb-0">7/24</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-start p-1 popover-section">
            <PopoverBox title="Kargo Çözümleri" content={popoverContent.kargo} activePopover={activePopover} setActivePopover={setActivePopover} id="kargo" />
            <PopoverBox title="E-Fatura" content={popoverContent.efatura} activePopover={activePopover} setActivePopover={setActivePopover} id="efatura" />
            <PopoverBox title="API Desteği" content={popoverContent.api} activePopover={activePopover} setActivePopover={setActivePopover} id="api" />
            <PopoverBox title="Ödeme Çözümleri" content={popoverContent.odeme} activePopover={activePopover} setActivePopover={setActivePopover} id="odeme" />
          </Col>
        </Row>
        <Row>
          <Col className="border-bottom border-secondary"></Col>
        </Row>
      </Container>

      {/* Referanslar Bölümü */}
      <Container fluid className="references-container text-light py-3 mt-4" id='referanslar' style={{ order: 2 }}>
        <Container className="references-head px-2 px-md-4">
          <Row className="mb-4">
            <Col className="references-comment text-warning text-center">
              <h6 style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>REFERANSLAR</h6>
              <h2 className='text-light'>Kullanıcı Yorumları</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="mb-3">
              <div className="p-3 p-md-4 d-flex flex-column justify-content-between reference-box">
                <div>
                  <h4 className="references-boxes text-warning">
                    <FaQuoteLeft className="me-2" />
                    Best Creative Agency!
                  </h4>
                  <p>Lectus quis sit diam aenean. Neque sed blandit sed at. Adipiscing eu aenean viverra nunc. Lacus ornare massa scelerisque feugiat pretium diam massa purus. Ultrices elementum mattis magna cras viverra nisl enim.</p>
                  <div style={{ marginTop: '20px', width: '100%', height: '1px', backgroundColor: 'white' }}></div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Image src={Image1} roundedCircle width="80" height="80" className="me-3" />
                  <div>
                    <h6 className="mb-0">Mark Daly</h6>
                    <p className="mb-0">Square Agency</p>
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} md={6} className="mb-3">
              <div className="p-3 p-md-4 d-flex flex-column justify-content-between reference-box">
                <div>
                  <h4 className="references-boxes text-warning">
                    <FaQuoteLeft className="me-2" />
                    Great Work!
                  </h4>
                  <p>Ultrices sed sed ut posuere at. Risus urna augue orci malesuada etiam diam enim. Parturient habitasse massa feugiat posuere odio leo vitae feugiat. Scelerisque cras viverra nunc ipsum et duis dui proin a.</p>
                  <div style={{ marginTop: '20px', width: '100%', height: '1px', backgroundColor: 'white' }}></div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Image src={Image2} roundedCircle width="80" height="80" className="me-3" />
                  <div>
                    <h6 className="mb-0">Olivia Segio</h6>
                    <p className="mb-0">Oria Studio</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
};

export default Services;
