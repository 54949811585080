import React, { useState } from 'react';
import { Container, Row, Col, Table, Button, Pagination, Offcanvas } from 'react-bootstrap';
import '../../assets/css/DetailsPrice.css';
import CalculateCargo from './CalculateCargo'; // CalculateCargo bileşenini burada kullanacağız

const UpsDetails = () => {
  const [show, setShow] = useState(true);
  const [showCalculate, setShowCalculate] = useState(false); // İkinci Offcanvas için state

  const handleClose = () => setShow(false);
  const handleCalculateClose = () => setShowCalculate(false);
  const handleCalculateShow = () => setShowCalculate(true);

  return (
    <>
      {/* İlk Offcanvas */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>UPS KARGO FİYATLARI</Offcanvas.Title>
          <Button variant="black" onClick={handleClose} style={{ marginLeft: '20px' }}>
            X
          </Button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Container>
            <Row className="justify-content-center my-1">
              <Col xs={12} md={10} lg={8}>
                <div className="my-3 text-center">
                  <button 
                    className="calc-btn btn btn-warning"
                    onClick={handleCalculateShow}  // İkinci Offcanvas'ı açar
                  >
                    KARGO DESİ / FİYAT HESAPLA
                  </button>
                </div>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>DESİ / KG</th>
                      <th>FİYAT</th>
                      <th>FİYAT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DOSYA</td>
                      <td>50,00₺</td>
                      <td>60,00₺</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>50,00₺</td>
                      <td>60,00₺</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>50,00₺</td>
                      <td>60,00₺</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>50,00₺</td>
                      <td>60,00₺</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>50,00₺</td>
                      <td>60,00₺</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>50,00₺</td>
                      <td>60,00₺</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>50,00₺</td>
                      <td>60,00₺</td>
                    </tr>
                  </tbody>
                </Table>
                <Pagination className="justify-content-center">
                  <Pagination.Item>1</Pagination.Item>
                  <Pagination.Item>2</Pagination.Item>
                  <Pagination.Item>3</Pagination.Item>
                </Pagination>
              </Col>
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>

      {/* İkinci Offcanvas, CalculateCargo bileşeni ile */}
      <Offcanvas show={showCalculate} onHide={handleCalculateClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Kargo Desi/Fiyat Hesapla</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CalculateCargo /> {/* CalculateCargo bileşeni burada render ediliyor */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UpsDetails;
