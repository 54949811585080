import React from 'react';
import { Container, Row, Col, Card, Button, Dropdown, Form } from 'react-bootstrap';

const apiUrl = process.env.REACT_APP_API_URL;

const Customer = () => {
  return (
    <Container fluid className="py-4">
      {/* Yeni Müşteri Ekle ve Arama Bölümü */}
      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Button variant="warning" className="w-100">YENİ MÜŞTERİ EKLE</Button>
        </Col>
        <Col xs={12} md={6}>
          <Form.Control type="text" placeholder="Müşteri Ad, Soyad, Telefon ile ara." />
        </Col>
      </Row>

      {/* Müşteri Listesi */}
      <Card>
        <Card.Header>MÜŞTERİLER</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} className="d-flex justify-content-between align-items-center mb-2 pb-2" style={{ borderBottom: '1px solid #dee2e6' }}>
              <div>
                <p><strong>gbdsbsd</strong></p>
                <p className=''>bsfbdsbsd</p>
                <p>455555555555</p>
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="warning">
                  İŞLEMLER
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">DÜZENLE</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">SİL</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">KARGO OLUŞTUR</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">E-FATURA OLUŞTUR</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col xs={12} className="d-flex justify-content-between align-items-center mb-2 pb-2" style={{ borderBottom: '1px solid #dee2e6' }}>
              <div>
                <p><strong>ojhgfdsa</strong></p>
                <p>lkjhgfd</p>
                <p>07777777777777777777</p>
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="warning">
                  İŞLEMLER
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">DÜZENLE</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">SİL</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">KARGO OLUŞTUR</Dropdown.Item>
                  <Dropdown.Item href="#/action-4">E-FATURA OLUŞTUR</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Customer;
