import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Profile = () => {
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    tcNo: '',
    birthDate: '',
    email: '',
    phone: '',
    profileImage: '',
    senderName: '',
    companyName: '',
    taxNumber: '',
    taxOffice: ''
  });

  const [logoPreview, setLogoPreview] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(''); // Güncelleme durumu için state
 const apiUrl = process.env.REACT_APP_API_URL;
const fetchUserData = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${apiUrl}/user`, {
      headers: { 'x-auth-token': token }
    });
    setUser(response.data);
    if (response.data.profileImage) {
      setLogoPreview(response.data.profileImage);
    }
  } catch (error) {
    console.error('Kullanıcı verileri alınırken bir hata oluştu:', error);
  }
};

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleEmailVerification = async () => {
    try {
      const token = localStorage.getItem('token');
   const response = await axios.post(`${apiUrl}/auth/send-verification-email`, {}, {
        headers: { 'x-auth-token': token }
      });
      if (response.data) {
        setUpdateStatus('Doğrulama e-postası başarıyla gönderildi. Lütfen e-postanızı kontrol edin.');
      }
    } catch (error) {
      console.error('Doğrulama e-postası gönderilirken bir hata oluştu:', error);
      setUpdateStatus('Doğrulama e-postası gönderilirken bir hata oluştu.');
    }
  };

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedLogo(file);
    }
  };

  const handleLogoSave = async () => {
    if (selectedLogo) {
      try {
        const formData = new FormData();
        formData.append('logo', selectedLogo);

        const token = localStorage.getItem('token');
      const response = await axios.post(`${apiUrl}/user/logo`, formData, {
          headers: { 
            'x-auth-token': token,
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data) {
          setUpdateStatus('Logo başarıyla kaydedildi!');
          fetchUserData();  // Kullanıcı verilerini yeniden yükleyin
        }
      } catch (error) {
        console.error('Logo yüklenirken bir hata oluştu:', error);
        setUpdateStatus('Logo yüklenirken bir hata oluştu.');
      }
    }
  };

  const handleAccountDeletion = async () => {
  try {
    const token = localStorage.getItem('token');
    await axios.delete(`${apiUrl}/user/delete`, {
      headers: { 'x-auth-token': token }
    });
    localStorage.removeItem('token'); // Token'ı localStorage'dan sil
    window.location.href = '/'; // Kullanıcıyı ana sayfaya yönlendir
  } catch (error) {
    console.error('Hesap silinirken bir hata oluştu:', error);
    setUpdateStatus('Hesap silinirken bir hata oluştu.');
  }
};


  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={8} className="mx-auto">
          <div className="text-center p-4" style={{ backgroundColor: 'white', borderRadius: '30px', color: 'gray' }}>
            {/* Güncelleme durumunu gösteren mesaj */}
            {updateStatus && (
              <Alert variant="info" onClose={() => setUpdateStatus('')} dismissible>
                {updateStatus}
              </Alert>
            )}

            <div className="profile-avatar mb-4" style={{ textAlign: 'center' }}>
              <label 
                htmlFor="logo-upload" 
                style={{
                  cursor: 'pointer', 
                  display: 'inline-block', 
                  borderRadius: '50%', 
                  overflow: 'hidden',
                  width: '120px', 
                  height: '120px',
                  backgroundColor: '#FFD700',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}
              >
                {logoPreview ? (
                  <img 
                    src={logoPreview} 
                    alt="Profile Logo" 
                    className="rounded-circle" 
                    style={{ width: '120px', height: '120px', objectFit: 'cover' }}
                  />
                ) : (
                  <div 
                    className="d-flex align-items-center justify-content-center"
                    style={{ 
                      width: '120px', 
                      height: '120px', 
                      fontSize: '36px',
                      fontWeight: 'bold',
                      color: 'black'
                    }}>
                    {`${user.firstName[0]}${user.lastName[0]}`}
                  </div>
                )}
                <input 
                  id="logo-upload" 
                  type="file" 
                  accept="image/*" 
                  onChange={handleLogoUpload} 
                  style={{ display: 'none' }}
                />
              </label>
              <h4 className="mt-3">{`${user.firstName} ${user.lastName}`}</h4>
              <p>{user.email}</p>
              <p>{user.phone}</p>
              {selectedLogo && (
                <Button variant="primary" className="mt-2" onClick={handleLogoSave}>Logoyu Kaydet</Button>
              )}
            </div>

            <h5 className="mt-4">KİMLİK BİLGİLERİ</h5>
            <hr />
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="firstName">
                    <Form.Label>İsim</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.firstName} 
                      readOnly 
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="lastName">
                    <Form.Label>Soyisim</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.lastName} 
                      readOnly 
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="tcNo">
                    <Form.Label>TC No</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.tcNo} 
                      readOnly 
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="birthDate">
                    <Form.Label>Doğum Tarihi</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={new Date(user.birthDate).toLocaleDateString()} 
                      readOnly 
                    />
                  </Form.Group>
                </Col>
              </Row>

              <p className="mt-4">
                Kimlik Bilgilerinizde Değişiklik Yapmaya İhtiyacınız Var ise, Lütfen Bizimle <a href="/contact" style={{ color: 'orange' }}>İletişime</a> Geçin.
              </p>
            </Form>

            <h5 className="mt-4">İLETİŞİM BİLGİLERİ</h5>
            <hr />
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="phone">
                    <Form.Label>Telefon Numarası</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.phone} 
                      readOnly 
                    />
                  </Form.Group>
                  <p className="mt-4" style={{ color: 'orange' }}>
                    Telefon numaranızı güncellemek için lütfen bizimle <a href="/contact" style={{ color: 'orange' }}>iletişime</a> geçin.
                  </p>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="email">
                    <Form.Label>E-Posta Adresi</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.email} 
                      readOnly 
                    />
                  </Form.Group>
                  <Button variant="danger" className="w-100 mt-2" onClick={handleEmailVerification}>
                    E-Posta Adresimi Doğrula
                  </Button>
                </Col>
              </Row>
            </Form>

            <h5 className="mt-4">GÖNDERİCİ BİLGİLERİ</h5>
            <hr />
            <Form>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="senderName">
                    <Form.Label>Gönderici Adı/Ünvanı</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.senderName} 
                      onChange={(e) => setUser({...user, senderName: e.target.value})}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="companyName">
                    <Form.Label>Şirket Ünvanı</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.companyName} 
                      onChange={(e) => setUser({...user, companyName: e.target.value})}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="taxNumber">
                    <Form.Label>Vergi Numarası</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.taxNumber} 
                      onChange={(e) => setUser({...user, taxNumber: e.target.value})}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="taxOffice">
                    <Form.Label>Vergi Dairesi</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={user.taxOffice} 
                      onChange={(e) => setUser({...user, taxOffice: e.target.value})}
                    />
                  </Form.Group>
                </Col>
              </Row>

            </Form>

           <Link to="/panelim/kaldır" className="btn btn-danger mt-4">
              Hesabımı Kapat
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
