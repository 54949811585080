import React from 'react';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';

const Payment = () => {
  return (
    <Container fluid className="py-4">
      {/* Filter Section */}
      <Row className="mb-4">
        <Col md={4}>
          <Card className="h-100">
            <Card.Header>Durumuna Göre Filtrele</Card.Header>
            <Card.Body>
              <Form.Check type="radio" id="bekliyor" label="Ödeme Bekliyor" name="durum" />
              <Form.Check type="radio" id="tamamlandi" label="Ödeme Tamamlandı" name="durum" />
              <Form.Check type="radio" id="iptalEdildi" label="Ödeme İptal Edildi" name="durum" />
              <Button variant="warning" className="w-100 mt-3">Filtrele</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100">
            <Card.Header>Müşteri Isım Veya Telefon Ile Arama</Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Control type="text" placeholder="Müşteri Isım / Soy Isım veya Telefon no Ile Arayın" />
              </Form.Group>
              <Button variant="warning" className="w-100 mt-3">Ara</Button>
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="h-100">
            <Card.Header>Tarih Aralığı Ile Filtrele</Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Control type="text" placeholder="Başlangıç Tarihi" />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Control type="text" placeholder="Bitiş Tarihi" />
              </Form.Group>
              <Button variant="warning" className="w-100 mt-3">Ara</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Payments List Section */}
      <Row>
        <Col>
          <Card>
            <Card.Header>Online Ödemeler</Card.Header>
            <Card.Body>
              {/* Payments Table */}
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Müşteri Adı</th>
                    <th>Ödeme Tarihi</th>
                    <th>Tutar</th>
                    <th>Durum</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Data to be populated from backend */}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Payment;
